// store/plugins/persist.js
export default store => {
  store.subscribe((mutation, { accessToken, user }) => {
    switch (mutation.type) {
      case 'setAccessToken':
        localStorage.setItem('accessToken', accessToken)
        break
      case 'setUser':
        localStorage.setItem('user', JSON.stringify(user))
        break
      case 'logout':
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        break
    }
  })
}
