import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './assets/fonts/inter/inter.css'
import './styles/variables.css'
import './styles/animation.css'
import './styles/main.css'
import socket from './plugins/socket'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(i18n)
app.use(socket)
app.mount('#app')
