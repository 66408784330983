// store/modules/company.js
export default {
  namespaced: true,
  state: () => ({
    company: null
  }),
  mutations: {
    setCompany (state, companyData) {
      state.company = companyData
    }
  },
  actions: {
    updateCompany ({ commit }, companyData) {
      commit('setCompany', companyData)
    }
  },
  getters: {
    company: state => state.company
  }
}
