<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.13889 2.46333C9.61222 0.512222 12.3878 0.512222 12.8611 2.46333C12.9321 2.75644 13.0714 3.02864 13.2675 3.25778C13.4636 3.48691 13.711 3.6665 13.9896 3.78194C14.2682 3.89738 14.5702 3.94539 14.8709 3.92208C15.1716 3.89876 15.4625 3.80478 15.72 3.64778C17.4344 2.60333 19.3978 4.56556 18.3533 6.28111C18.1966 6.53851 18.1027 6.82926 18.0795 7.12975C18.0562 7.43023 18.1042 7.73197 18.2195 8.01042C18.3348 8.28888 18.5142 8.5362 18.743 8.73228C18.9719 8.92836 19.2438 9.06767 19.5367 9.13889C21.4878 9.61222 21.4878 12.3878 19.5367 12.8611C19.2436 12.9321 18.9714 13.0714 18.7422 13.2675C18.5131 13.4636 18.3335 13.711 18.2181 13.9896C18.1026 14.2682 18.0546 14.5702 18.0779 14.8709C18.1012 15.1716 18.1952 15.4625 18.3522 15.72C19.3967 17.4344 17.4344 19.3978 15.7189 18.3533C15.4615 18.1966 15.1707 18.1027 14.8703 18.0795C14.5698 18.0562 14.268 18.1042 13.9896 18.2195C13.7111 18.3348 13.4638 18.5142 13.2677 18.743C13.0716 18.9719 12.9323 19.2438 12.8611 19.5367C12.3878 21.4878 9.61222 21.4878 9.13889 19.5367C9.06787 19.2436 8.92864 18.9714 8.73254 18.7422C8.53644 18.5131 8.28901 18.3335 8.01039 18.2181C7.73176 18.1026 7.42982 18.0546 7.12913 18.0779C6.82844 18.1012 6.5375 18.1952 6.28 18.3522C4.56556 19.3967 2.60222 17.4344 3.64667 15.7189C3.80345 15.4615 3.89728 15.1707 3.92054 14.8703C3.9438 14.5698 3.89583 14.268 3.78052 13.9896C3.66522 13.7111 3.48584 13.4638 3.25697 13.2677C3.02809 13.0716 2.75618 12.9323 2.46333 12.8611C0.512222 12.3878 0.512222 9.61222 2.46333 9.13889C2.75644 9.06787 3.02864 8.92864 3.25778 8.73254C3.48691 8.53644 3.6665 8.28901 3.78194 8.01039C3.89738 7.73176 3.94539 7.42982 3.92208 7.12913C3.89876 6.82844 3.80478 6.5375 3.64778 6.28C2.60333 4.56556 4.56556 2.60222 6.28111 3.64667C7.39222 4.32222 8.83222 3.72444 9.13889 2.46333Z"
            stroke="#939393" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M8 11C8 11.7956 8.31607 12.5587 8.87868 13.1213C9.44129 13.6839 10.2044 14 11 14C11.7956 14 12.5587 13.6839 13.1213 13.1213C13.6839 12.5587 14 11.7956 14 11C14 10.2044 13.6839 9.44129 13.1213 8.87868C12.5587 8.31607 11.7956 8 11 8C10.2044 8 9.44129 8.31607 8.87868 8.87868C8.31607 9.44129 8 10.2044 8 11Z"
            stroke="#939393" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
  name: 'SettingsIcon'
}
</script>
