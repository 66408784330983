<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Bills</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div class="clients__container">
            <Suspense>
                <template #default>
                    <div class="table__container">
                        <div class="table__filter">
                            <div class="table__search">
                                <div class="table__searchInput">
                                    <input type="text" v-model="searchQuery" @keyup.enter="fetchElements"
                                        :placeholder="$t('companies.searchPlaceholder')">
                                </div>
                            </div>
                            <div class="table__filterForm">
                                <form @submit.prevent="applyFilter" @reset="resetFilter">
                                    <label>{{ $t('companies.registration') }}</label>
                                    <DatePicker v-model:value="dateFrom"
                                        :placeholder="$t('companies.dateFromPlaceholder')" type="date"
                                        format="YYYY-MM-DD" />
                                    <DatePicker v-model:value="dateTo" :placeholder="$t('companies.dateToPlaceholder')"
                                        type="date" format="YYYY-MM-DD" />
                                    <button type="submit">{{ $t('companies.apply') }}</button>
                                    <button type="reset">{{ $t('companies.reset') }}</button>
                                </form>
                            </div>
                        </div>
                        <div class="adminTable">
                            <div class="adminTable__header">
                                <div class="adminTable__col w-5">#</div>
                                <div class="adminTable__col w-10" @click="sortTable('id')"
                                    :class="{ active: sortKey === 'id' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.id') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('status')"
                                    :class="{ active: sortKey === 'status' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.status') }}
                                </div>
                                <div class="adminTable__col w-15" @click="sortTable('client')"
                                    :class="{ active: sortKey === 'client' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.client') }}
                                </div>
                                <div class="adminTable__col w-15" @click="sortTable('matter')"
                                    :class="{ active: sortKey === 'matter' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.matter') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('due_date')"
                                    :class="{ active: sortKey === 'due_date' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.dueDate') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('issue_date')"
                                    :class="{ active: sortKey === 'issue_date' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.issueDate') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('paid')"
                                    :class="{ active: sortKey === 'paid' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.paid') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('amount')"
                                    :class="{ active: sortKey === 'amount' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.amount') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('status_active')"
                                    :class="{ active: sortKey === 'status_active' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('bills.statusActive') }}
                                </div>
                                <div class="adminTable__col w-5">{{ $t('bills.action') }}</div>
                            </div>
                            <div v-if="loading" class="adminTable__body">
                              <div v-for="n in 10" :key="n" class="adminTable__row shimmer-table-row">
                                <div class="adminTable__col w-5 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-20 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-10 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-5 shimmer shimmer-box"></div>
                              </div>
                            </div>
                            <div v-else class="adminTable__body">
                                <div v-for="item in elements" :key="item.id" class="adminTable__row">
                                    <div class="adminTable__col w-5"><input type="checkbox" name="selectedCompany"
                                            :value="item.id"></div>
                                    <div class="adminTable__col w-10">
                                      <router-link :to="'/companies/' + company.id + '/bills/' + item.id" class="adminTable__userName">{{ item.id }}</router-link>
                                    </div>
                                    <div class="adminTable__col w-10">{{ item.status }}</div>
                                    <div class="adminTable__col w-15">
                                      <router-link v-if="item.client" :to="'/companies/' + company.id + '/contacts/' + item.client.id" class="adminTable__userName">{{ item.client.name }}</router-link>
                                    </div>
                                    <div class="adminTable__col w-15">
                                      <router-link v-if="item.matters" :to="'/companies/' + company.id + '/matters/' + item.matters[0].id" class="adminTable__userName">{{ item.matters[0].name }}</router-link>
                                    </div>
                                    <div class="adminTable__col w-10">{{ item.due_date }}</div>
                                    <div class="adminTable__col w-10">{{ item.issue_date }}</div>
                                    <div class="adminTable__col w-10">{{ item.paid_formatted }}</div>
                                    <div class="adminTable__col w-10">{{ item.amount_formatted }}</div>
                                    <div class="adminTable__col w-10">
                                        <div :class="['adminTable__userStatus', item.status_active ? 'active' : 'inactive']">
                                            {{ item.status ? $t('companies.table.active') : $t('companies.table.inactive')
                                            }}
                                        </div>
                                    </div>
                                    <div class="adminTable__col w-5 flex a-center j-end">
                                        <svg @click="openMenu(item.id)" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                                fill="black" />
                                        </svg>
                                        <div v-if="openMenuId === item.id" class="adminTable__rowMenu">
                                            <div class="adminTable__rowMenuList">
                                                <div class="adminTable__rowMenuItem" @click="editElement(item.id)">
                                                    <div class="adminTable__rowMenuItemText">{{  $t('companies.menu.edit') }}</div>
                                                </div>
                                                <div class="adminTable__rowMenuItem" @click="toggleStatus(item)">
                                                    <div class="adminTable__rowMenuItemText">{{ item.status ? $t('companies.menu.deactivate') : $t('companies.menu.activate') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="adminTable__footer">
                                <div class="adminTable__resultPerPage">{{ paginationInfo }}</div>
                                <div class="adminTable__navigation">
                                    <div class="adminTable__navPrev" :class="{ disabled: currentPage === 1 }"
                                        @click="prevPage">&lt;</div>
                                    <div v-for="page in generatePageNumbers()" :key="page" class="adminTable__navNum"
                                        :class="{ active: currentPage === page, ellipsis: page === '...' }"
                                        @click="page !== '...' && goToPage(page)">{{ page }}</div>
                                    <div class="adminTable__navNext" :class="{ disabled: currentPage === totalPages }"
                                        @click="nextPage">&gt;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #fallback>
                </template>
            </Suspense>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getBills, changeCompanyStatus, getCompanyDetails } from '@/api'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import { format } from 'date-fns'

export default {
  components: {
    DatePicker,
    Breadcrumbs
  },
  data () {
    return {
      elements: [],
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      pageSize: 10,
      totalElements: 0,
      loading: false,
      sortKey: '',
      sortOrder: 1,
      openMenuId: null,
      isModalOpen: false,
      selectedUserId: null,
      companyId: null,
      company: null
    }
  },
  created () {
    this.loading = true
    this.companyId = this.$route.params.id
    this.fetchCompanyDetails()
  },
  computed: {
    ...mapState(['user']),
    paginationInfo () {
      return `${this.pageSize * (this.currentPage - 1) + 1}-${Math.min(this.pageSize * this.currentPage, this.totalElements)} ${this.$t('companies.table.resultText')} ${this.totalElements}`
    },
    totalPages () {
      return Math.ceil(this.totalElements / this.pageSize)
    }
  },
  methods: {
    formatDate (timestamp) {
      const date = new Date(timestamp * 1000)
      return format(date, 'MM/dd/yyyy HH:mm')
    },
    async fetchCompanyDetails () {
      this.loading = true
      try {
        const response = await getCompanyDetails(this.companyId)
        this.$store.dispatch('company/updateCompany', response.data)
        this.owner = response.data.owner
        this.company = response.data
        this.updateBreadcrumbs()
      } catch (error) {
        console.error('Error fetching clients:', error)
      } finally {
        this.loading = false // Скрыть спиннер
      }
    },
    async fetchElements () {
      this.elements = [] // Clear the existing
      this.loading = true
      const params = {
        search: this.searchQuery,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: this.currentPage,
        pageSize: this.pageSize,
        sortKey: this.sortKey,
        sortOrder: this.sortOrder
      }
      try {
        const response = await getBills(this.companyId, params)
        this.elements = response.data.bills.map(element => {
          return {
            ...element,
            due_date: this.formatDate(element.due_date),
            issue_date: this.formatDate(element.issue_date)
          }
        })
        this.totalElements = response.data.total
      } catch (error) {
        console.error('Error fetching elements:', error)
      } finally {
        this.loading = false // Скрыть спиннер
      }
    },
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
      this.selectedUserId = null
    },
    openMenu (companyId) {
      this.openMenuId = this.openMenuId === companyId ? null : companyId
    },
    editElement (companyId) {
      console.log('Edit user with ID:', companyId)
      // Add your logic for editing a user
      this.openMenuId = null
    },
    async toggleStatus (element) {
      const newStatus = element.status ? 'disable' : 'active'
      try {
        await changeCompanyStatus(element.id, newStatus)
        element.status = !element.status
      } catch (error) {
        console.error('Error toggling user status:', error)
      } finally {
        this.openMenuId = null
      }
    },
    addNote (companyId) {
      console.log('Add note for user with ID:', companyId)
      // Add your logic for adding a note
      this.openMenuId = null
    },
    writeMessage (companyId) {
      this.selectedUserId = companyId
      this.openModal()
      this.openMenuId = null
    },
    sortTable (key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1 // Toggle sort order
      } else {
        this.sortKey = key
        this.sortOrder = 1 // Default to ascending
      }
      this.fetchElements()
    },
    applyFilter () {
      this.currentPage = 1
      this.fetchElements()
    },
    resetFilter () {
      this.searchQuery = ''
      this.dateFrom = ''
      this.dateTo = ''
      this.currentPage = 1
      this.fetchElements()
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fetchElements()
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.fetchElements()
      }
    },
    goToPage (page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page
        this.fetchElements()
      }
    },
    generatePageNumbers () {
      const pages = []
      const totalPages = this.totalPages
      const currentPage = this.currentPage

      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i)
        }
      } else {
        if (currentPage <= 4) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        } else if (currentPage >= totalPages - 3) {
          pages.push(1)
          pages.push('...')
          for (let i = totalPages - 4; i <= totalPages; i++) {
            pages.push(i)
          }
        } else {
          pages.push(1)
          pages.push('...')
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        }
      }

      return pages
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Bills' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  },
  mounted () {
    this.fetchElements()
  }
}
</script>

<style scoped>
.mainButton {
    padding: 8px 10px;
    height: 40px;
}

.clients__container {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    overflow: auto;
}
</style>
