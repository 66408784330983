<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit client #{{ elementId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('clients.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="mainButton" @click="authorizeAndOpenCRM(form.basic_info.id)" >{{ $t('company.login') }}</div>
                        <CustomInput id="first_name" :label="$t('clients.first_name')"
                            v-model="form.basic_info.first_name" />
                        <CustomInput id="last_name" :label="$t('clients.last_name')"
                            v-model="form.basic_info.last_name" />
                        <CustomInput id="login" :label="$t('clients.login')" v-model="form.basic_info.login" />
                        <CustomInput id="email" :label="$t('clients.email')" v-model="form.basic_info.email" />
                        <CustomInput id="phone" :label="$t('clients.phone')" v-model="form.basic_info.phone" />
                        <CustomInput id="work_position" :label="$t('clients.work_position')"
                            v-model="form.work_info.work_position" />
                        <CustomInput id="billing_rate" :label="$t('clients.billing_rate')"
                            v-model="form.work_info.billing_rate" />
                        <CustomSelect id="subscription" :label="$t('clients.subscription')"
                            v-model="form.subscription.subscribe_type" :options="subscriptionOptions" />
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <CustomInput id="street" :label="$t('clients.street')" v-model="form.address.street" />
                        <CustomInput id="city" :label="$t('clients.city')" v-model="form.address.city" />
                        <CustomInput id="state" :label="$t('clients.state')" v-model="form.address.state" />
                        <CustomInput id="zip" :label="$t('clients.zip')" v-model="form.address.zip" />
                        <CustomSelect id="country" :label="$t('clients.country')" v-model="form.address.country"
                            :options="countriesOptions" />
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3>Permissions</h3>
                <div class="flex flex-col g-8 mb-24" v-for="(actions, permission) in permissionsJson" :key="permission">
                    <h4>{{ formatPermissionLabel(permission) }}</h4>
                    <div class="flex flex-row w-50 g-24">
                        <div v-for="action in Object.keys(actions)" :key="action">
                            <CustomCheckbox :id="`${permission}_${action}`" :label="action"
                                v-model="permissionsJson[permission][action]" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveElement" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getClientDetails, saveClientDetails, authorizeUser } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomInput from '@/components/form/CustomInput.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'

export default {
  components: {
    Breadcrumbs,
    CustomInput,
    CustomSelect,
    CustomCheckbox
  },
  data () {
    return {
      dateFrom: '',
      countriesOptions: [],
      subscriptionOptions: [],
      clientOptions: [],
      form: {
        name: ''
      },
      loading: false,
      company: null,
      companyId: null,
      elementId: null,
      isSaving: false,
      message: null,
      permissionsJson: null
    }
  },
  created () {
    this.elementId = this.$route.params.clientId
    this.loadInitialData()
  },
  methods: {
    async authorizeAndOpenCRM (userId) {
      try {
        const result = await authorizeUser(userId)
        if (result.data.session_token) {
          window.open(`https://crm.pensli.com/a/${userId}/?token=${result.data.session_token}`, '_blank')
        } else {
          alert('Authorization failed')
        }
      } catch (error) {
        console.error('Error during authorization:', error)
      }
    },
    async saveElement () {
      this.isSaving = true
      this.message = null

      const formData = new FormData()

      // Основные поля
      // Append basic information fields to formData
      formData.append('first_name', this.form.basic_info.first_name)
      formData.append('last_name', this.form.basic_info.last_name)
      formData.append('login', this.form.basic_info.login)
      formData.append('email', this.form.basic_info.email)
      formData.append('phone', this.form.basic_info.phone)
      formData.append('work_position', this.form.work_info.work_position)
      formData.append('billing_rate', this.form.work_info.billing_rate)

      // Append address information fields to formData
      formData.append('street', this.form.address.street)
      formData.append('city', this.form.address.city)
      formData.append('state', this.form.address.state)
      formData.append('zip', this.form.address.zip)
      formData.append('country', this.form.address.country)

      // Append subscription type to formData
      formData.append('subscription_type', this.form.subscription.subscribe_type)

      // Serialize permissions for transmission
      const filteredPermissions = {}
      for (const [permissionGroup, actions] of Object.entries(this.permissionsJson)) {
        filteredPermissions[permissionGroup] = Object.keys(actions).filter(action => actions[action])
      }
      formData.append('permissions', JSON.stringify(filteredPermissions))

      try {
        const response = await saveClientDetails(this.elementId, formData)
        console.log('response: ', response)
        if (response.status === 'Success') {
          this.message = 'Client saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        this.updateBreadcrumbs()

        const elementResponse = await getClientDetails(this.elementId)
        this.permissionsJson = this.initializePermissionsFromData(elementResponse.data.work_info.permissions)
        this.form = { ...this.form, ...elementResponse.data }

        this.countriesOptions = [
          { value: '21', name: 'الإمارات العربية المتحدة' },
          { value: '83', name: 'الكويت' },
          { value: '114', name: 'المملكة العربية السعودية' },
          { value: '171', name: 'عمان' },
          { value: '172', name: 'قطر' },
          { value: '145', name: 'مملكة البحرين' }
        ]
        this.subscriptionOptions = [
          { value: 'solicitor', name: 'Solicitor' },
          { value: 'non_solicitor', name: 'Non-Solicitor' }
        ]
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Clients', path: '/clients' },
        { name: `Client #${this.elementId}`, path: '' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    },
    initializePermissionsFromData (permissionsData) {
      const permissionsTemplate = {
        PERM_CALENDAR: ['add', 'delete', 'edit'],
        PERM_TASKS: ['add', 'delete', 'edit'],
        PERM_MATTERS: ['add', 'delete', 'edit'],
        PERM_MATTER_FINANCIAL: ['see', 'add_fees', 'quick_bill', 'generate', 'approve', 'record_payment'],
        PERM_CONTACTS: ['add', 'delete', 'edit'],
        PERM_ACTIVITIES: ['add', 'delete', 'edit'],
        PERM_BILLS: ['add', 'delete', 'edit', 'see', 'add_fees', 'quick_bill', 'generate', 'approve', 'record_payment'],
        PERM_DOCUMENTS: ['add', 'delete', 'edit'],
        PERM_REPORTS: ['see'],
        PERM_SETTINGS: ['account_payment', 'manage_users', 'group_job_titels', 'profile', 'practice', 'billing', 'firm_feed']
      }

      const initializedPermissions = {}
      // Loop over each key in the template to initialize states
      Object.keys(permissionsTemplate).forEach(permission => {
        initializedPermissions[permission] = {}
        permissionsTemplate[permission].forEach(action => {
          // Set true if the action is included in the data received; otherwise, false
          initializedPermissions[permission][action] = permissionsData[permission]?.includes(action) || false
        })
      })

      return initializedPermissions
    },
    formatPermissionLabel (permission) {
      return permission
        .replace('PERM_', '')
        .replace(/_/g, ' ')
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
