// store/breadcrumbs.js
export default {
  namespaced: true,
  state: () => ({
    breadcrumbs: []
  }),
  mutations: {
    setBreadcrumbs (state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    }
  },
  actions: {
    updateBreadcrumbs ({ commit }, breadcrumbs) {
      commit('setBreadcrumbs', breadcrumbs)
    }
  },
  getters: {
    breadcrumbs: state => state.breadcrumbs
  }
}
