<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit bill #{{ elementId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('bill.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <CustomCheckbox id="active" :label="$t('bill.active')" v-model="form.status_active" />
                        <CustomSelect id="status" :label="$t('bill.status')" :searchable="true" v-model="form.status" :options="statusOptions" />
                        <CustomSelect id="client" :label="$t('bill.client')" :searchable="true" v-model="form.client" :options="clientOptions" />
                        <CustomMultiselect id="matter" :label="$t('bill.matter')" :searchable="true" v-model="form.matters" :options="mattersOptions" />
                        <CustomDate id="due_date" :label="$t('bill.dueDate')" :type="'date'" :format="'MM/DD/YYYY'" v-model:modelValue="form.due_date" />
                        <CustomDate id="issue_date" :label="$t('bill.issueDate')" :type="'date'" :format="'MM/DD/YYYY'" v-model:modelValue="form.issue_date" />
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveElement" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getBillDetails, getCompanyDetails, getMatters, saveBillDetails, getContacts } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomDate from '@/components/form/CustomDate.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'
import CustomMultiselect from '@/components/form/CustomMultiselect.vue'

export default {
  components: {
    Breadcrumbs,
    CustomSelect,
    CustomDate,
    CustomCheckbox,
    CustomMultiselect
  },
  data () {
    return {
      dateFrom: '',
      statusOptions: [],
      form: {
        name: ''
      },
      loading: false,
      company: null,
      companyId: null,
      elementId: null,
      isSaving: false,
      message: null
    }
  },
  created () {
    this.companyId = this.$route.params.companyId
    this.elementId = this.$route.params.billId
    this.loadInitialData()
  },
  methods: {
    async saveElement () {
      this.isSaving = true
      this.message = null

      const formData = new FormData()

      // Основные поля
      console.log('this.form.date: ', this.form.date)
      formData.append('duration', this.form.hours)
      formData.append('description', this.form.description)
      formData.append('date', this.form.date)
      formData.append('due_date', this.form.due_date)
      formData.append('status', this.form.status)
      formData.append('matter', this.form.matter?.id)
      formData.append('type', this.form.type)
      formData.append('firm_user', this.form.user?.id)
      formData.append('rate', this.form.rate)
      formData.append('activity_category', this.form.category_id)
      formData.append('court', this.form.court)
      formData.append('amount', this.form.amount)

      try {
        const response = await saveBillDetails(this.companyId, this.elementId, formData)
        console.log('response: ', response)
        if (response.status === 'Success') {
          this.message = 'Activity saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        const companyResponse = await getCompanyDetails(this.companyId)
        this.company = companyResponse.data
        this.updateBreadcrumbs()

        const [mattersResponse, clientResponse] = await Promise.all([
          getMatters(this.companyId, { pageSize: 'all' }),
          getContacts(this.companyId, { pageSize: 'all' })
        ])

        this.clientOptions = clientResponse.data.contacts.map(client => ({
          value: client.id,
          name: client.is_company ? client.first_name : (client.first_name + ' ' + client.last_name).trim()
        }))
        this.mattersOptions = mattersResponse.data.matters.map(matter => ({
          value: matter.id,
          name: matter.name
        }))

        const elementResponse = await getBillDetails(this.companyId, this.elementId)
        console.log(elementResponse)
        this.form = { ...this.form, ...elementResponse.data }

        this.statusOptions = [
          { value: '121', name: 'Draft' },
          { value: '123', name: 'Awaiting payment' },
          { value: '124', name: 'Paid' }
        ]
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Bills', path: `/companies/${this.companyId}/bills` },
        { name: `Bill #${this.elementId}`, path: '' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
