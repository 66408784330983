import apiClient from './apiClient'

export const getCompanyDetails = async (companyId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const getCompanyUsageStats = async (companyId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/subscription`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const getCompanyUsageFunctions = async (companyId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/usage_functions`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const getCompanyMembers = async (companyId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/members`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const getLastFiles = async (companyId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/last_files`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const getCompanyNotes = async (companyId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/notes`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const getCompanies = async (params) => {
  try {
    const response = await apiClient.get('/companies', { params })
    return response.data
  } catch (error) {
    console.error('Error fetching companies:', error)
    throw error
  }
}

export const changeCompanyStatus = async (compnyId, status) => {
  try {
    const formData = new FormData()
    formData.append('company_id', compnyId)
    formData.append('status', status)
    const response = await apiClient.post('/companies/change_status', formData)
    return response.data
  } catch (error) {
    console.error('Error changing company status:', error)
    throw error
  }
}

export const addNoteCompany = async (params) => {
  try {
    const companyId = params.companyId
    const formData = new FormData()
    formData.append('message', params.message)
    formData.append('headline', params.headline)
    const response = await apiClient.post(`/companies/${companyId}/add_note`, formData)
    return response.data
  } catch (error) {
    console.error('Error add note:', error)
    throw error
  }
}
