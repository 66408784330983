import apiClient from './apiClient'

export const getEvents = async (companyId, params) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/events`, {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching tasks:', error)
    throw error
  }
}

export const getEventDetails = async (companyId, eventId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/events/${eventId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const saveEventDetails = async (companyId, eventId, formData) => {
  try {
    const response = await apiClient.post(`/companies/${companyId}/events/${eventId}/edit`, formData)
    return response.data
  } catch (error) {
    console.error('Error saving matter details:', error)
    throw error
  }
}
