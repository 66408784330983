import io from 'socket.io-client'

const socket = io('https://crm.pensli.com:449', {
  path: '/socket.io',
  timeout: 20000
})

export default {
  install (app) {
    app.config.globalProperties.$socket = socket
  }
}
