<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit contact #{{ elementId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('contact.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <CustomCheckbox id="active" :label="$t('contact.active')" v-model="form.status" />
                        <CustomCheckbox id="is_company" :label="$t('contact.isCompany')" v-model="form.is_company" />
                        <CustomInput id="first_name" :label="$t('contact.firstName')" v-model="form.first_name" />
                        <CustomInput id="flast_name" :label="$t('contact.lastName')" v-model="form.last_name" />
                        <CustomInput id="title" :label="$t('contact.title')" v-model="form.title" />

                        <CustomInput id="phone_primary" :label="$t('contact.phonePrimary')"
                            v-model="form.phone_primary" />
                        <div v-for="(phone, index) in form.phone" :key="`phone-${index}`">
                            <CustomInput :id="`phone-${index}`" :label="$t('contact.phone')"
                                v-model="form.phone[index]" />
                        </div>
                        <CustomInput id="email_primary" :label="$t('contact.emailPrimary')"
                            v-model="form.email_primary" />
                        <div v-for="(email, index) in form.email" :key="`email-${index}`">
                            <CustomInput :id="`email-${index}`" :label="$t('contact.email')"
                                v-model="form.email[index]" />
                        </div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <CustomSelect id="country" :label="$t('contact.country')" :searchable="true"
                            v-model="form.country" :options="countryOptions" />
                        <CustomInput id="region" :label="$t('contact.region')" v-model="form.region" />
                        <CustomInput id="city" :label="$t('contact.city')" v-model="form.city" />
                        <CustomInput id="district" :label="$t('contact.district')" v-model="form.district" />
                        <CustomInput id="street" :label="$t('contact.street')" v-model="form.street" />
                        <CustomInput id="zip_code" :label="$t('contact.zip_code')" v-model="form.zip_code" />
                        <CustomInput id="building_number" :label="$t('contact.building_number')"
                            v-model="form.building_number" />
                        <CustomInput id="additional_code" :label="$t('contact.additional_code')"
                            v-model="form.additional_code" />
                        <CustomInput id="unit_number" :label="$t('contact.unit_number')" v-model="form.unit_number" />
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveElement" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getContactDetails, getCompanyDetails, saveContactDetails } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomInput from '@/components/form/CustomInput.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'

export default {
  components: {
    Breadcrumbs,
    CustomInput,
    CustomSelect,
    CustomCheckbox
  },
  data () {
    return {
      dateFrom: '',
      countryOptions: [],
      form: {
        name: ''
      },
      loading: false,
      company: null,
      companyId: null,
      elementId: null,
      isSaving: false,
      message: null
    }
  },
  created () {
    this.companyId = this.$route.params.companyId
    this.elementId = this.$route.params.contactId
    this.loadInitialData()
  },
  methods: {
    async saveElement () {
      this.isSaving = true
      this.message = null

      const formData = new FormData()

      formData.append('first_name', this.form.first_name)
      formData.append('last_name', this.form.last_name)
      formData.append('status', this.form.status)
      formData.append('is_company', this.form.is_company)
      formData.append('title', this.form.title)
      formData.append('phone_primary', this.form.phone_primary)
      formData.append('email_primary', this.form.email_primary)
      formData.append('country', this.form.country)
      formData.append('region', this.form.region)
      formData.append('city', this.form.city)
      formData.append('district', this.form.district)
      formData.append('street', this.form.street)
      formData.append('zip_code', this.form.zip_code)
      formData.append('building_number', this.form.building_number)
      formData.append('additional_code', this.form.additional_code)
      formData.append('unit_number', this.form.unit_number)

      // Appending multiple phone numbers and emails
      this.form.phone.forEach((phone, index) => {
        formData.append(`phone[${index}]`, phone)
      })

      this.form.email.forEach((email, index) => {
        formData.append(`email[${index}]`, email)
      })

      try {
        const response = await saveContactDetails(this.companyId, this.elementId, formData)
        console.log('response: ', response)
        if (response.status === 'Success') {
          this.message = 'Activity saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        const companyResponse = await getCompanyDetails(this.companyId)
        this.company = companyResponse.data
        this.updateBreadcrumbs()

        const elementResponse = await getContactDetails(this.companyId, this.elementId)
        this.form = { ...this.form, ...elementResponse.data }

        this.countryOptions = [
          { value: '', name: '' }
        ]
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Contacts', path: `/companies/${this.companyId}/contacts` },
        { name: `Contact #${this.elementId}`, path: '' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
