<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <textarea :id="id" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                  :rows="rows" :placeholder="placeholder"></textarea>
    </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    modelValue: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 4
    },
    placeholder: {
      type: String,
      default: ''
    }
  }
}
</script>
