<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <Multiselect :id="id" v-model="model" :searchable="searchable" :options="options" :multiple="multiple"
            :taggable="taggable" :create-option="createOption" placeholder="Select option" track-by="name" label="name" />
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  components: { Multiselect },
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [Object, String, Boolean],
      default: () => ({})
    },
    searchable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    createOption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get () {
        if (this.modelValue && typeof this.modelValue === 'object') {
          return this.modelValue.id
        } else {
          return this.modelValue
        }
      },
      set (newValue) {
        if (typeof this.modelValue === 'object') {
          this.$emit('update:modelValue', { id: newValue })
        } else {
          this.$emit('update:modelValue', newValue)
        }
      }
    }
  }

}
</script>
