// src/api/apiService.js
import apiClient from './apiClient'

export const getCompaniesStats = async () => {
  try {
    const response = await apiClient.get('/stats/companies')
    return response.data
  } catch (error) {
    console.error('Error fetching companies stats:', error)
    throw error
  }
}

export const getMattersStats = async () => {
  try {
    const response = await apiClient.get('/stats/matters')
    return response.data
  } catch (error) {
    console.error('Error fetching matters stats:', error)
    throw error
  }
}

export const getDonutChartData = async () => {
  try {
    const response = await apiClient.get('/stats/donut')
    return response.data
  } catch (error) {
    console.error('Failed to fetch donut chart data:', error)
    throw error
  }
}

export const getUsersStats = async (period = 'days') => {
  try {
    const response = await apiClient.get(`/stats/users?period=${period}`)
    return response.data
  } catch (error) {
    console.error('Failed to fetch users stats:', error)
    throw error
  }
}

export const getSubscriptionEndsSoon = async () => {
  try {
    const response = await apiClient.get('/stats/subscriptions')
    return response.data
  } catch (error) {
    console.error('Failed to fetch subscibtions stats:', error)
    throw error
  }
}
