// src/store/index.js
import {
  createStore
} from 'vuex'
import breadcrumbs from './modules/breadcrumbs'
import company from './modules/company'
import persistPlugin from './plugins/persist'
import {
  login,
  logout
} from '@/api'

const store = createStore({
  plugins: [persistPlugin],
  modules: {
    breadcrumbs,
    company
  },
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    user: JSON.parse(localStorage.getItem('user')) || null
  },
  mutations: {
    setAccessToken (state, token) {
      state.accessToken = token
      localStorage.setItem('accessToken', token)
    },
    setUser (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
    },
    logout (state) {
      state.accessToken = null
      state.user = null
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.accessToken,
    getUser: (state) => state.user
  },
  actions: {
    authenticateUser ({
      commit
    }, {
      email,
      password
    }) {
      return new Promise((resolve, reject) => {
        login(email, password)
          .then((response) => {
            console.log('Login response:', response)
            if (response.data.status === 'success') {
              commit('setAccessToken', response.data.access_token)
              commit('setUser', response.data.user)
              resolve(response)
            } else {
              console.error('Authentication failed: ', response.data)
              reject(new Error('Authentication failed'))
            }
          })
          .catch((error) => {
            console.error('Error during authentication:', error)
            if (error.response) {
              console.error('Error response data:', error.response.data)
              console.error('Error response status:', error.response.status)
              console.error('Error response headers:', error.response.headers)
            } else if (error.request) {
              console.error('Error request:', error.request)
            } else {
              console.error('Error message:', error.message)
            }
            reject(error)
          })
      })
    },
    logoutUser ({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        logout(state.accessToken)
          .then((response) => {
            commit('logout')
            resolve(response)
          })
          .catch((error) => {
            console.error('Error during logout:', error)
            reject(error)
          })
      })
    }
  }
})

export default store
