<template>
  <div :class="['sideMenu__item', { 'active': isActive }]" @click="handleClick">
    <div class="sideMenu__itemIcon">
      <component :is="resolveIconComponent(icon)" />
    </div>
    <div class="sideMenu__itemName">{{ $t(name) }}</div>
    <div v-if="name === 'menu.chat' && unreadMessagesCount > 0 && !isActive" class="sideMenu__itemUnreadMessages">
      {{ unreadMessagesCount }}
    </div>
  </div>
</template>

<script>
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import ClientsIcon from '@/components/icons/ClientsIcon.vue'
import CompaniesIcon from '@/components/icons/CompaniesIcon.vue'
import ChatIcon from '@/components/icons/ChatIcon.vue'
import HistoryIcon from '@/components/icons/HistoryIcon.vue'
import SubscriptionsIcon from '@/components/icons/SubscriptionsIcon.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      unreadMessagesCount: 0
    }
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    route: {
      type: String,
      required: true
    }
  },
  computed: {
    isActive () {
      return this.$route.path.startsWith(this.route)
    }
  },
  created () {
    this.$socket.on('unreadCountUpdated', ({ count }) => {
      this.unreadMessagesCount = count
    })
  },
  methods: {
    ...mapActions(['logoutUser']),
    navigate () {
      this.$router.push(this.route)
    },
    async handleClick () {
      if (this.route === '/logout') {
        try {
          await this.logoutUser()
          this.$router.push('/login')
        } catch (error) {
          console.error('Error during logout:', error)
        }
      } else {
        this.navigate()
      }
    },
    resolveIconComponent (iconName) {
      const icons = {
        DashboardIcon,
        ClientsIcon,
        CompaniesIcon,
        ChatIcon,
        HistoryIcon,
        SubscriptionsIcon,
        SettingsIcon,
        LogoutIcon
      }
      return icons[iconName] || null
    }
  }
}
</script>

<style scoped>
.sideMenu__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
}

.sideMenu__itemName {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  color: var(--text-color);
}

.sideMenu__itemIcon {
  width: 24px;
  display: flex;
  align-items: center;
}

.sideMenu__item:hover {
  background: var(--background-hover);
  cursor: pointer;
}

.sideMenu__item:hover .sideMenu__itemName {
  cursor: pointer;
  color: var(--main-color);
}

.sideMenu__item:hover ::v-deep .sideMenu__itemIcon>svg path:not([fill]) {
  stroke: var(--main-color);
}

.sideMenu__item:hover ::v-deep .sideMenu__itemIcon>svg path[fill] {
  fill: var(--main-color);
}

.sideMenu__item.active {
  background: var(--background-hover);
}

.sideMenu__item:active .sideMenu__itemName {
  transform: scale(0.98);
}

.sideMenu__item.active .sideMenu__itemName {
  color: var(--main-color);
}

.sideMenu__item.active ::v-deep .sideMenu__itemIcon>svg path:not([fill]) {
  stroke: var(--main-color);
}

.sideMenu__item.active ::v-deep .sideMenu__itemIcon>svg path[fill] {
  fill: var(--main-color);
}

.sideMenu__itemUnreadMessages{
  background-color: #e94848;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
