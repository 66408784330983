<template>
    <div class="main__container">
        <div class="flex j-btw">
            <h1>{{ $t('clients.title') }}</h1>
            <div class="mainButton" @click="openModal">
                <div class="iconButton">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.33333 4.99999H9.66667M4.33333 7.66666H8.33333M11 1.66666C11.5304 1.66666 12.0391 1.87737 12.4142 2.25244C12.7893 2.62752 13 3.13622 13 3.66666V8.99999C13 9.53042 12.7893 10.0391 12.4142 10.4142C12.0391 10.7893 11.5304 11 11 11H7.66667L4.33333 13V11H3C2.46957 11 1.96086 10.7893 1.58579 10.4142C1.21071 10.0391 1 9.53042 1 8.99999V3.66666C1 3.13622 1.21071 2.62752 1.58579 2.25244C1.96086 1.87737 2.46957 1.66666 3 1.66666H11Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="textButton">{{ $t('clients.sendMessage') }}</div>
            </div>
        </div>
        <div class="clients__container">
            <Suspense>
                <template #default>
                    <div class="table__container">
                        <div class="table__filter">
                            <div class="table__search">
                                <div class="table__searchInput">
                                    <input type="text" v-model="searchQuery" @keyup.enter="fetchClients"
                                        :placeholder="$t('clients.searchPlaceholder')">
                                </div>
                            </div>
                            <div class="table__filterForm">
                                <form @submit.prevent="applyFilter" @reset="resetFilter">
                                    <label>{{ $t('clients.registration') }}</label>
                                    <DatePicker v-model:value="dateFrom"
                                        :placeholder="$t('clients.dateFromPlaceholder')" type="date"
                                        format="YYYY-MM-DD" />
                                    <DatePicker v-model:value="dateTo" :placeholder="$t('clients.dateToPlaceholder')"
                                        type="date" format="YYYY-MM-DD" />
                                    <button type="submit">{{ $t('clients.apply') }}</button>
                                    <button type="reset">{{ $t('clients.reset') }}</button>
                                </form>
                            </div>
                        </div>
                        <div class="adminTable">
                            <div class="adminTable__header">
                                <div class="adminTable__col w-5">#</div>
                                <div class="adminTable__col w-20" @click="sortTable('name')"
                                    :class="{ active: sortKey === 'name' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('clients.table.name') }}
                                </div>
                                <div class="adminTable__col w-20" @click="sortTable('email')"
                                    :class="{ active: sortKey === 'email' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('clients.table.email') }}
                                </div>
                                <div class="adminTable__col w-15" @click="sortTable('last_activity')"
                                    :class="{ active: sortKey === 'last_activity' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('clients.table.lastActivity') }}
                                </div>
                                <div class="adminTable__col w-15" @click="sortTable('date_register')"
                                    :class="{ active: sortKey === 'date_register' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('clients.table.dateRegister') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('customer')"
                                    :class="{ active: sortKey === 'customer' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('clients.table.customer') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('status')"
                                    :class="{ active: sortKey === 'status' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('clients.table.status') }}
                                </div>
                                <div class="adminTable__col w-5">{{ $t('clients.table.action') }}</div>
                            </div>
                            <div v-if="loading" class="adminTable__body">
                              <div v-for="n in 10" :key="n" class="adminTable__row shimmer-table-row">
                                <div class="adminTable__col w-5 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-20 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-10 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-5 shimmer shimmer-box"></div>
                              </div>
                            </div>
                            <div v-else class="adminTable__body">
                                <div v-for="client in clients" :key="client.id" class="adminTable__row">
                                    <div class="adminTable__col w-5"><input type="checkbox" name="selectedUser"
                                            :value="client.id"></div>
                                    <div class="adminTable__col w-20">
                                        <div class="adminTable__user">
                                            <div class="adminTable__userAvatar"><img
                                                    :src="client.avatar || defaultAvatar" alt="avatar"></div>
                                            <router-link :to="'/clients/' + client.id">{{ client.name }}</router-link>
                                            <div class="adminTable__userIsOwner" v-if="client.is_owner">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.00017 0.333313C10.6822 0.333313 13.6668 3.31798 13.6668 6.99998C13.6692 8.53858 13.1371 10.0302 12.1615 11.22L12.1748 11.2346L12.0868 11.3093C11.4617 12.0488 10.6826 12.6429 9.80398 13.05C8.9254 13.4572 7.96851 13.6676 7.00017 13.6666C5.0335 13.6666 3.26684 12.8153 2.04684 11.462L1.9135 11.3086L1.8255 11.2353L1.83884 11.2193C0.863342 10.0298 0.331251 8.53836 0.333503 6.99998C0.333503 3.31798 3.31817 0.333313 7.00017 0.333313ZM7.00017 10.3333C5.76017 10.3333 4.6395 10.728 3.80484 11.2706C4.72645 11.9622 5.84797 12.3351 7.00017 12.3333C8.15236 12.3351 9.27389 11.9622 10.1955 11.2706C9.24175 10.6595 8.13293 10.3342 7.00017 10.3333ZM7.00017 1.66665C5.99652 1.66662 5.01323 1.94979 4.16332 2.48361C3.31341 3.01744 2.63137 3.78024 2.19561 4.68436C1.75985 5.58848 1.58804 6.5972 1.69994 7.5946C1.81183 8.59199 2.2029 9.53757 2.82817 10.3226C3.90884 9.54731 5.3835 8.99998 7.00017 8.99998C8.61684 8.99998 10.0915 9.54731 11.1722 10.3226C11.7974 9.53757 12.1885 8.59199 12.3004 7.5946C12.4123 6.5972 12.2405 5.58848 11.8047 4.68436C11.369 3.78024 10.6869 3.01744 9.83702 2.48361C8.98711 1.94979 8.00382 1.66662 7.00017 1.66665ZM7.00017 2.99998C7.70741 2.99998 8.38569 3.28093 8.88579 3.78103C9.38588 4.28112 9.66684 4.9594 9.66684 5.66665C9.66684 6.37389 9.38588 7.05217 8.88579 7.55226C8.38569 8.05236 7.70741 8.33331 7.00017 8.33331C6.29293 8.33331 5.61465 8.05236 5.11455 7.55226C4.61445 7.05217 4.3335 6.37389 4.3335 5.66665C4.3335 4.9594 4.61445 4.28112 5.11455 3.78103C5.61465 3.28093 6.29293 2.99998 7.00017 2.99998ZM7.00017 4.33331C6.64655 4.33331 6.30741 4.47379 6.05736 4.72384C5.80731 4.97389 5.66684 5.31302 5.66684 5.66665C5.66684 6.02027 5.80731 6.35941 6.05736 6.60945C6.30741 6.8595 6.64655 6.99998 7.00017 6.99998C7.35379 6.99998 7.69293 6.8595 7.94298 6.60945C8.19303 6.35941 8.3335 6.02027 8.3335 5.66665C8.3335 5.31302 8.19303 4.97389 7.94298 4.72384C7.69293 4.47379 7.35379 4.33331 7.00017 4.33331Z"
                                                        fill="#0058B4" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="adminTable__col w-20"><a :herf="'mailto:' + client.email">{{
                client.email }}</a></div>
                                    <div class="adminTable__col w-15">{{ client.last_activity }}</div>
                                    <div class="adminTable__col w-15">{{ client.date_register }}</div>
                                    <div class="adminTable__col w-10">{{ client.is_customer }}</div>

                                    <div class="adminTable__col w-10">
                                        <div :class="['adminTable__userStatus', client.status ? 'active' : 'inactive']">
                                            {{ client.status ? $t('clients.table.active') : $t('clients.table.inactive')
                                            }}
                                        </div>
                                    </div>
                                    <div class="adminTable__col w-5 flex a-center j-end">
                                        <svg @click="openMenu(client.id)" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                                fill="black" />
                                        </svg>
                                        <div v-if="openMenuId === client.id" class="adminTable__rowMenu">
                                            <div class="adminTable__rowMenuList">
                                                <div class="adminTable__rowMenuItem">
                                                    <div class="adminTable__rowMenuItemText"><router-link :to="'/clients/' + client.id">{{  $t('clients.menu.editUser') }}</router-link></div>
                                                </div>
                                                <div class="adminTable__rowMenuItem" @click="toggleUserStatus(client)">
                                                    <div class="adminTable__rowMenuItemText">{{ client.status ? $t('clients.menu.deactivate') : $t('clients.menu.activate') }}</div>
                                                </div>
                                                <div class="adminTable__rowMenuItem" @click="addNote(client.id)">
                                                    <div class="adminTable__rowMenuItemText">{{  $t('clients.menu.addNote') }}</div>
                                                </div>
                                                <div v-if="user.id != client.id" class="adminTable__rowMenuItem">
                                                  <div class="adminTable__rowMenuItemText"><router-link :to="'/chat/' + client.id">{{  $t('clients.menu.writeMessage') }}</router-link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="adminTable__footer">
                                <div class="adminTable__resultPerPage">{{ paginationInfo }}</div>
                                <div class="adminTable__navigation">
                                    <div class="adminTable__navPrev" :class="{ disabled: currentPage === 1 }"
                                        @click="prevPage">&lt;</div>
                                    <div v-for="page in generatePageNumbers()" :key="page" class="adminTable__navNum"
                                        :class="{ active: currentPage === page, ellipsis: page === '...' }"
                                        @click="page !== '...' && goToPage(page)">{{ page }}</div>
                                    <div class="adminTable__navNext" :class="{ disabled: currentPage === totalPages }"
                                        @click="nextPage">&gt;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #fallback>
                </template>
            </Suspense>
        </div>
        <SendMessageModal v-if="isModalOpen" :selected-user-id="selectedUserId" @close="closeModal"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getClients, changeUserStatus } from '@/api'
import defaultAvatar from '@/assets/default_avatar.svg'
import DatePicker from 'vue-datepicker-next'
import SendMessageModal from '@/components/clients/SendMessageModal.vue'
import 'vue-datepicker-next/index.css'

export default {
  components: {
    DatePicker,
    SendMessageModal
  },
  data () {
    return {
      clients: [],
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      pageSize: 10,
      totalClients: 0,
      defaultAvatar,
      loading: false,
      sortKey: '',
      sortOrder: 1,
      openMenuId: null,
      isModalOpen: false,
      selectedUserId: null
    }
  },
  computed: {
    ...mapState(['user']),
    paginationInfo () {
      return `${this.pageSize * (this.currentPage - 1) + 1}-${Math.min(this.pageSize * this.currentPage, this.totalClients)} ${this.$t('clients.table.resultText')} ${this.totalClients}`
    },
    totalPages () {
      return Math.ceil(this.totalClients / this.pageSize)
    }
  },
  methods: {
    async fetchClients () {
      this.clients = []
      this.loading = true
      const params = {
        search: this.searchQuery,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: this.currentPage,
        pageSize: this.pageSize,
        sortKey: this.sortKey,
        sortOrder: this.sortOrder
      }
      try {
        const response = await getClients(params)
        this.clients = response.data.clients
        this.totalClients = response.data.total
      } catch (error) {
        console.error('Error fetching clients:', error)
      } finally {
        this.loading = false // Скрыть спиннер
      }
    },
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
      this.selectedUserId = null
    },
    openMenu (clientId) {
      this.openMenuId = this.openMenuId === clientId ? null : clientId
    },
    editUser (clientId) {
      console.log('Edit user with ID:', clientId)
      // Add your logic for editing a user
      this.openMenuId = null
    },
    async toggleUserStatus (client) {
      const newStatus = client.status ? 'disable' : 'active'
      try {
        await changeUserStatus(client.id, newStatus)
        client.status = !client.status
      } catch (error) {
        console.error('Error toggling user status:', error)
      } finally {
        this.openMenuId = null
      }
    },
    addNote (clientId) {
      console.log('Add note for user with ID:', clientId)
      // Add your logic for adding a note
      this.openMenuId = null
    },
    writeMessage (clientId) {
      this.selectedUserId = clientId
      this.openModal()
      this.openMenuId = null
    },
    sortTable (key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1 // Toggle sort order
      } else {
        this.sortKey = key
        this.sortOrder = 1 // Default to ascending
      }
      this.fetchClients()
    },
    applyFilter () {
      this.currentPage = 1
      this.fetchClients()
    },
    resetFilter () {
      this.searchQuery = ''
      this.dateFrom = ''
      this.dateTo = ''
      this.currentPage = 1
      this.fetchClients()
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fetchClients()
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.fetchClients()
      }
    },
    goToPage (page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page
        this.fetchClients()
      }
    },
    generatePageNumbers () {
      const pages = []
      const totalPages = this.totalPages
      const currentPage = this.currentPage

      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i)
        }
      } else {
        if (currentPage <= 4) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        } else if (currentPage >= totalPages - 3) {
          pages.push(1)
          pages.push('...')
          for (let i = totalPages - 4; i <= totalPages; i++) {
            pages.push(i)
          }
        } else {
          pages.push(1)
          pages.push('...')
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        }
      }

      return pages
    }
  },
  mounted () {
    this.fetchClients()
  }
}
</script>

<style scoped>
.mainButton {
    padding: 8px 10px;
    height: 36px;
}

.clients__container {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    overflow: auto;
}
</style>
