<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <Multiselect :id="id" v-model="model" mode="tags" :searchable="searchable" :options="options"
            :taggable="taggable" :create-option="createOption" :close-on-select="false" placeholder="Select option"
            track-by="name" label="name" />
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  components: { Multiselect },
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [Object, Array],
      default: () => ({})
    },
    searchable: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'tags'
    },
    taggable: {
      type: Boolean,
      default: false
    },
    createOption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get () {
        // Извлекаем значения 'value' из модели 'modelValue' для соответствия структуре 'options'
        if (Array.isArray(this.modelValue)) {
          return this.modelValue.map(item => item.id) // Мы преобразуем 'id' в 'value'
        } else if (this.modelValue) {
          return this.modelValue.id // Мы преобразуем 'id' в 'value'
        }
        return null
      },
      set (newValue) {
        // Принимаем новое значение и обновляем 'modelValue' на основе выбора из 'options'
        if (this.mode === 'tags' || this.multiple) {
          // Из 'options' выбираем объекты, где 'value' соответствует 'newValue'
          const updatedValue = this.options.filter(option => newValue.includes(option.value)).map(option => ({ id: option.value, name: option.name }))
          this.$emit('update:modelValue', updatedValue)
        } else {
          // Находим один объект из 'options', который соответствует 'newValue'
          const foundOption = this.options.find(option => option.value === newValue)
          const updatedValue = foundOption ? { id: foundOption.value, name: foundOption.name } : null
          this.$emit('update:modelValue', updatedValue)
        }
      }
    }
  }

}
</script>
