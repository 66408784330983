import apiClient from './apiClient'

export const getContacts = async (companyId, params) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/contacts`, {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching tasks:', error)
    throw error
  }
}

export const getContactDetails = async (companyId, contactId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/contacts/${contactId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const saveContactDetails = async (companyId, contactId, formData) => {
  try {
    const response = await apiClient.post(`/companies/${companyId}/contacts/${contactId}/edit`, formData)
    return response.data
  } catch (error) {
    console.error('Error saving matter details:', error)
    throw error
  }
}
