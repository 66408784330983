<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <DatePicker :id="id" v-model:value="formattedDate" :type="type" :format="format" :placeholder="placeholder" />
    </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'

export default {
  components: {
    DatePicker
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    modelValue: {
      type: Number,
      default: () => Math.floor(Date.now() / 1000)
    },
    format: {
      type: String,
      default: 'MM/DD/YYYY'
    },
    type: {
      type: String,
      default: 'date'
    },
    placeholder: {
      type: String,
      default: 'Select date'
    }
  },
  computed: {
    formattedDate: {
      get () {
        // Преобразование timestamp в объект Date для отображения в DatePicker
        return this.modelValue ? new Date(this.modelValue * 1000) : ''
      },
      set (value) {
        // Преобразование выбранной даты обратно в timestamp и отправка через событие update
        this.$emit('update:modelValue', Math.floor(new Date(value).getTime() / 1000))
      }
    }
  }
}
</script>
