<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.636 18.364C7.10845 19.8365 9.04594 20.7528 11.1183 20.9568C13.1906 21.1608 15.2696 20.6399 17.0009 19.4828C18.7322 18.3257 20.0087 16.6041 20.6129 14.6113C21.217 12.6185 21.1115 10.4778 20.3142 8.55414C19.5169 6.63045 18.0772 5.04276 16.2404 4.06164C14.4037 3.08051 12.2835 2.76667 10.2413 3.17358C8.19909 3.58049 6.36116 4.68299 5.04073 6.29318C3.72031 7.90338 2.99909 9.92163 3 12.004V14"
            stroke="#939393" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 12L3 14L5 12M11 8V13H16" stroke="#939393" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
  name: 'HistoryIcon'
}
</script>
