import apiClient from './apiClient'

export const getTasks = async (companyId, params) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/tasks`, { params })
    return response.data
  } catch (error) {
    console.error('Error fetching tasks:', error)
    throw error
  }
}

export const getTaskDetails = async (companyId, taskId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/tasks/${taskId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const saveTaskDetails = async (companyId, taskId, formData) => {
  try {
    const response = await apiClient.post(`/companies/${companyId}/tasks/${taskId}/edit`, formData)
    return response.data
  } catch (error) {
    console.error('Error saving matter details:', error)
    throw error
  }
}
