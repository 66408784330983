import apiClient from './apiClient'

export const getPracticeAreas = async (companyId, params) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/practice_areas`, { params })
    return response.data
  } catch (error) {
    console.error('Error fetching matters:', error)
    throw error
  }
}
