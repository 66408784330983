import apiClient from './apiClient'

export const getBills = async (companyId, params) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/bills`, {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching tasks:', error)
    throw error
  }
}

export const getBillDetails = async (companyId, billId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/bills/${billId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const saveBillDetails = async (companyId, billId, formData) => {
  try {
    const response = await apiClient.post(`/companies/${companyId}/bills/${billId}/edit`, formData)
    return response.data
  } catch (error) {
    console.error('Error saving matter details:', error)
    throw error
  }
}
