<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-form-container">
        <form @submit.prevent="onSubmit">
          <h1>{{ $t('authentication.login') }}</h1>
          <div class="login-form-body">
            <div class="form-group">
              <label for="email"><svg width="18" height="20" viewBox="0 0 18 20">
                  <use href="#form_one_user"></use>
                </svg> {{ $t('authentication.email') }}</label>
              <input type="email" id="email" v-model="email" :placeholder="$t('authentication.writeYourEmail')">
              <p class="error" v-if="emailError">{{ $t('authentication.emailError') }}</p>
            </div>
            <div class="form-group">
              <label for="password"><svg width="16" height="21" viewBox="0 0 16 21" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <use href="#form_password"></use>
                </svg>{{ $t('authentication.password') }}</label>
              <input type="password" id="password" v-model="password" placeholder="********">
              <p class="error" v-if="passwordError">{{ $t('authentication.passwordError') }}</p>
            </div>
            <div class="form-check">
              <input type="checkbox" id="remember" v-model="remember">
              <label for="remember">{{ $t('authentication.rememberMe') }}</label>
            </div>
          </div>
          <button type="submit" :disabled="isFormInvalid">{{ $t('authentication.loginButton') }}</button>
        </form>
      </div>
      <div class="login-image-container">
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: '',
      remember: false,
      emailError: false,
      passwordError: false
    }
  },
  computed: {
    isFormInvalid () {
      return !this.email || !this.password
    }
  },
  methods: {
    ...mapActions(['authenticateUser']),
    onSubmit () {
      this.authenticateUser({ email: this.email, password: this.password })
        .then(() => {
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          console.error('Authentication error:', error)
          console.log('this.email:', this.email)
          console.log('this.password:', this.password)
          this.emailError = true
          this.passwordError = true
        })
    }
  }
}
</script>

<style scoped>
.login-page {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.login-page h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 36px;
  color: var(--black-text);
}

.login-container {
  display: flex;
  background-color: #fff;
  border-radius: 32px;
  width: 1200px;
  overflow: hidden;
}

.login-form-container {
  padding: 120px 40px 40px 40px;
  width: 50%;
}

.login-form-container form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
}

.login-form-body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.login-image-container {
  width: 50%;
  background-color: #eef3fc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background: url('../assets/login_image.png') no-repeat center center;
  padding: 120px 40px 40px 40px;
}

.login-image-container img {
  width: 80%;
  height: auto;
}
</style>
