import apiClient from './apiClient'

export const getMatters = async (companyId, params) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/matters`, { params })
    return response.data
  } catch (error) {
    console.error('Error fetching matters:', error)
    throw error
  }
}

export const getMatterDetails = async (companyId, matterId) => {
  try {
    const response = await apiClient.get(`/companies/${companyId}/matters/${matterId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const saveMatterDetails = async (companyId, matterId, formData) => {
  try {
    const response = await apiClient.post(`/companies/${companyId}/matters/${matterId}/edit`, formData)
    return response.data
  } catch (error) {
    console.error('Error saving matter details:', error)
    throw error
  }
}
