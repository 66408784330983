<template>
    <div class="form-group">
        <label :for="id">
            <input :id="id" type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)" />
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    modelValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
