<template>
  <nav aria-label="breadcrumb">
    <div class="breadcrumb">
      <div v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb__item">
        <div class="breadcrumb__itemTitle" :class="{ 'active': index === breadcrumbs.length - 1 }">
          <router-link v-if="crumb.path" :to="crumb.path">{{ crumb.name }}</router-link>
          <span v-else>{{ crumb.name }}</span>
        </div>
        <!-- Сепаратор для всех кроме последнего элемента -->
        <div v-if="index < breadcrumbs.length - 1" class="breadcrumb__separator"><svg width="10" height="12" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.80765 4.85355C5.00292 4.65829 5.00292 4.34171 4.80765 4.14645L1.62567 0.964466C1.43041 0.769204 1.11383 0.769204 0.918568 0.964466C0.723305 1.15973 0.723305 1.47631 0.918568 1.67157L3.74699 4.5L0.918568 7.32843C0.723305 7.52369 0.723305 7.84027 0.918568 8.03553C1.11383 8.2308 1.43041 8.2308 1.62567 8.03553L4.80765 4.85355ZM3.9541 5H4.4541V4H3.9541V5Z" fill="#C4C4C4"></path></svg></div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/breadcrumbs']
    }
  }
}
</script>

<style scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  gap: 4px;
}

.breadcrumb__item {
  display: flex;
  gap: 4px;
}

.breadcrumb__item a{
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  color: #b7b7b7;
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 8px;
}

.breadcrumb__item:hover a{
  background: var(--background-hover);
  color: var(--main-color);
}

.breadcrumb__item span {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  color: var(--black-text);
  padding: 4px 8px;
  border-radius: 8px;
}

.breadcrumb__separator {
  color: var(--black-text);
  display: flex;
  align-items: center;
}

.router-link-active {
  text-decoration: none;
  color: #000;
}
</style>
