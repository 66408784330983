<template>
    <div class="main__container">
        <div v-if="loading" class="flex flex-col j-btw g-16">
            <h1 class="shimmer " style="width: 80%; height: 36px;"></h1>
            <div class="shimmer" style="width: 20%; height: 23px;"></div>
        </div>
        <div v-else class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>{{ title }}</h1>
                <router-link :to="'/companies/' + companyId + '/edit'" class="company__ownerEdit">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 9.99994C18.7348 9.99994 18.4804 10.1053 18.2929 10.2928C18.1054 10.4804 18 10.7347 18 10.9999V16.9999C18 17.2652 17.8946 17.5195 17.7071 17.707C17.5196 17.8946 17.2652 17.9999 17 17.9999H3C2.73478 17.9999 2.48043 17.8946 2.29289 17.707C2.10536 17.5195 2 17.2652 2 16.9999V2.99994C2 2.73472 2.10536 2.48037 2.29289 2.29283C2.48043 2.1053 2.73478 1.99994 3 1.99994H9C9.26522 1.99994 9.51957 1.89458 9.70711 1.70705C9.89464 1.51951 10 1.26516 10 0.999939C10 0.734722 9.89464 0.480368 9.70711 0.292832C9.51957 0.105296 9.26522 -6.10352e-05 9 -6.10352e-05H3C2.20435 -6.10352e-05 1.44129 0.31601 0.87868 0.878619C0.316071 1.44123 0 2.20429 0 2.99994V16.9999C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 19.9999 3 19.9999H17C17.7956 19.9999 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 16.9999V10.9999C20 10.7347 19.8946 10.4804 19.7071 10.2928C19.5196 10.1053 19.2652 9.99994 19 9.99994ZM4 10.7599V14.9999C4 15.2652 4.10536 15.5195 4.29289 15.707C4.48043 15.8946 4.73478 15.9999 5 15.9999H9.24C9.37161 16.0007 9.50207 15.9755 9.62391 15.9257C9.74574 15.8759 9.85656 15.8026 9.95 15.7099L16.87 8.77994L19.71 5.99994C19.8037 5.90698 19.8781 5.79637 19.9289 5.67452C19.9797 5.55266 20.0058 5.42195 20.0058 5.28994C20.0058 5.15793 19.9797 5.02722 19.9289 4.90536C19.8781 4.7835 19.8037 4.6729 19.71 4.57994L15.47 0.289939C15.377 0.196211 15.2664 0.121816 15.1446 0.0710475C15.0227 0.0202789 14.892 -0.00585938 14.76 -0.00585938C14.628 -0.00585938 14.4973 0.0202789 14.3754 0.0710475C14.2536 0.121816 14.143 0.196211 14.05 0.289939L11.23 3.11994L4.29 10.0499C4.19732 10.1434 4.12399 10.2542 4.07423 10.376C4.02446 10.4979 3.99924 10.6283 4 10.7599ZM14.76 2.40994L17.59 5.23994L16.17 6.65994L13.34 3.82994L14.76 2.40994ZM6 11.1699L11.93 5.23994L14.76 8.06994L8.83 13.9999H6V11.1699Z"
                            fill="#0058B4" />
                    </svg>
                </router-link>
            </div>
            <Breadcrumbs />
        </div>
        <div class="main__body">
            <div class="flex j-btw w-100 g-24">
                <OwnerInfo @updateTitle="updateTitle" @loading="updateLoading" />
                <SubscriptionStats />
            </div>
            <div class="flex j-btw w-100 g-24">
                <UsageFunctions />
                <MembersList />
            </div>
            <div class="flex j-btw w-100 g-24">
                <LastFiles />
            </div>
            <div class="flex j-btw w-100 g-24">
                <NotesList />
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'

const OwnerInfo = defineAsyncComponent(() => import('@/components/company/OwnerInfo.vue'))
const SubscriptionStats = defineAsyncComponent(() => import('@/components/company/SubscriptionStats.vue'))
const UsageFunctions = defineAsyncComponent(() => import('@/components/company/UsageFunctions.vue'))
const MembersList = defineAsyncComponent(() => import('@/components/company/MembersList.vue'))
const LastFiles = defineAsyncComponent(() => import('@/components/company/LastFiles.vue'))
const NotesList = defineAsyncComponent(() => import('@/components/company/NotesList.vue'))

export default {
  components: {
    Breadcrumbs,
    OwnerInfo,
    SubscriptionStats,
    UsageFunctions,
    MembersList,
    LastFiles,
    NotesList
  },
  data () {
    return {
      title: null,
      loading: false,
      companyId: null
    }
  },
  created () {
    this.loading = true
    this.companyId = this.$route.params.id
  },
  methods: {
    updateTitle (newTitle) {
      this.title = newTitle
      this.loading = false
    },
    updateLoading (status) {
      this.loading = status
    }
  }
}
</script>
<style>
.company__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
}

.company__ownerContainer {
    flex-direction: row;
    gap: 24px;
}

.company__ownerAvatar {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    min-width: 192px;
    height: 192px;
}

.company__ownerAvatar img {
    width: 192px;
    height: 192px;
    -o-object-fit: cover;
    object-fit: cover;
}

.company__ownerInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.company__ownerInfoHeader {
    display: flex;
    gap: 16px;
    align-items: center;
}

.company__ownerTitle {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    color: var(--black-text);
}

.company__ownerStatus {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.company__ownerInfoDescription {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.company__ownerPosition {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 111%;
    color: var(--black-text);
}

.company__ownerEmail {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 111%;
    color: var(--text-color);
}

.company__ownerInfoFooter {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    gap: 8px;
}

.company__ownerLogin {
    width: 120px;
}

.company__ownerAction {
    width: 120px;
}

.company__ownerLogin .mainButton,
.company__ownerAction .mainButton {
    width: 100%;
    font-size: 16px;
}

.company__ownerEdit,
.company__ownerSendMessage {
    background: var(--background);
    border-radius: 8px;
    padding: 12px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company__ownerEdit:hover,
.company__ownerSendMessage:hover {
    cursor: pointer;
    background: var(--background-hover);

}

.company__box h4 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    color: var(--black-text);
}

.company__box {
    gap: 16px;
}

.company__subscription {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.company__subscriptionItem {
    display: flex;
    justify-content: space-between;
}

.company__subscriptionLabel {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--black-text);
}

.company__subscriptionValue {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--black-text);
}

.company__usage {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 24px;
    gap: 48px;
}

.company__usageItem {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px;
}

.company__usageItem:hover{
    background: var(--background-hover);
    cursor: pointer;
}

.company__usageValue {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 32px;
    line-height: 125%;
    color: var(--black-text);
}

.company__usageLable {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 111%;
    color: var(--black-text);
}

.company__usageDate {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 114%;
    text-align: center;
    color: var(--text-color);
}

.shimmer {
    overflow: hidden;
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
    background-repeat: no-repeat;
    position: relative;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmerAnimation;
    animation-timing-function: linear;
}

@keyframes shimmerAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.shimmer-box {
    height: 20px;
    margin: 4px 0;
    border-radius: 4px;
}

.shimmer-avatar {
    width: 192px;
    height: 192px;
    border-radius: 16px;
    /* background: #fff; */
}

.shimmer-label {
    width: 50%;
}

.shimmer-value {
    width: 30%;
}

.shimmer-last-file {
    width: 191px;
    height: 80px;
    border-radius: 16px;
}

.shimmer-notes{
    width: 32%;
    height: 200px;
    border-radius: 16px;
}

</style>
