// src/models/Message.js

export default class Message {
  constructor ({
    id,
    content,
    senderId,
    sendername,
    senderPhoto = null,
    chatId,
    createdAt,
    type = 'text',
    status = 'send',
    fileUrl = null,
    isOutgoing = true,
    isRead = false
  }) {
    this.id = id
    this.content = content
    this.senderId = senderId
    this.senderName = sendername
    this.senderPhoto = senderPhoto
    this.chatId = chatId
    this.createdAt = createdAt || Date.now()
    this.type = type
    this.status = status
    this.fileUrl = fileUrl
    this.isOutgoing = isOutgoing
    this.isRead = isRead
  }
}
