<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit activity #{{ elementId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('activity.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <CustomCheckbox id="active" :label="$t('activity.active')" v-model="form.status" />
                        <CustomInput id="hours" :label="$t('activity.duration')" v-model="form.hours" />
                        <CustomInput id="amount" :label="$t('activity.amount')" v-model="form.amount" />
                        <CustomSelect id="activity_category" :label="$t('activity.activity_category')" :searchable="true" v-model="form.category_id" :options="practiceOptions" />
                        <CustomTextarea id="description" :label="$t('activity.description')" v-model="form.description" />
                        <CustomInput id="court" :label="$t('activity.court')" v-model="form.court" />
                        <!-- <CustomInput id="file" :label="$t('activity.file')" v-model="form.file" /> -->
                        <CustomSelect id="invoice" :label="$t('activity.invoice')" :searchable="true" v-model="form.invoice" :options="invoiceOptions" />
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <CustomSelect id="matter" :label="$t('activity.matter')" :searchable="true" v-model="form.matter" :options="mattersOptions" />
                        <CustomDate id="date" :label="$t('activity.date')" :type="'datetime'" :format="'MM/DD/YYYY HH:mm:ss'" v-model:modelValue="form.date" />
                        <CustomDate id="due_date" :label="$t('activity.due_date')" :type="'datetime'" :format="'MM/DD/YYYY HH:mm:ss'" v-model:modelValue="form.due_date" />
                        <CustomSelect id="type" :label="$t('activity.type')" :searchable="true" v-model="form.type" :options="typesOptions" />
                        <CustomSelect id="user" :label="$t('activity.user')" :searchable="true" v-model="form.user" :options="solicitorOptions" />
                        <CustomInput id="rate" :label="$t('activity.rate')" v-model="form.rate" />
                        <CustomInput id="circle" :label="$t('activity.circle')" v-model="form.circle" />
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveElement" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyMembers, getActivityDetails, getCompanyDetails, getPracticeAreas, saveActivityDetails, getMatters, getBills } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomInput from '@/components/form/CustomInput.vue'
import CustomTextarea from '@/components/form/CustomTextarea.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomDate from '@/components/form/CustomDate.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'

export default {
  components: {
    Breadcrumbs,
    CustomInput,
    CustomTextarea,
    CustomSelect,
    CustomDate,
    CustomCheckbox
  },
  data () {
    return {
      dateFrom: '',
      typesOptions: [],
      invoiceOptions: [],
      solicitorOptions: [],
      practiceOptions: [],
      clientOptions: [],
      form: {
        name: ''
      },
      loading: false,
      company: null,
      companyId: null,
      elementId: null,
      isSaving: false,
      message: null
    }
  },
  created () {
    this.companyId = this.$route.params.companyId
    this.elementId = this.$route.params.activityId
    this.loadInitialData()
  },
  computed: {
    selectedTypes () {
      return this.typesOptions.find(option => option.name === this.form.type)
    }
  },
  methods: {
    async saveElement () {
      this.isSaving = true
      this.message = null

      const formData = new FormData()

      // Основные поля
      console.log('this.form.date: ', this.form.date)
      formData.append('duration', this.form.hours)
      formData.append('description', this.form.description)
      formData.append('date', this.form.date)
      formData.append('due_date', this.form.due_date)
      formData.append('status', this.form.status)
      formData.append('matter', this.form.matter?.id)
      formData.append('type', this.form.type)
      formData.append('firm_user', this.form.user?.id)
      formData.append('rate', this.form.rate)
      formData.append('activity_category', this.form.category_id)
      formData.append('court', this.form.court)
      formData.append('amount', this.form.amount)
      formData.append('invoice', this.form.invoice)

      try {
        const response = await saveActivityDetails(this.companyId, this.elementId, formData)
        console.log('response: ', response)
        if (response.status === 'Success') {
          this.message = 'Activity saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        const companyResponse = await getCompanyDetails(this.companyId)
        this.company = companyResponse.data
        this.updateBreadcrumbs()

        const [solicitorsResponse, mattersResponse, practiceResponse, billsResponse] = await Promise.all([
          getCompanyMembers(this.companyId),
          getMatters(this.companyId, { pageSize: 'all' }),
          getPracticeAreas(this.companyId),
          getBills(this.companyId, { pageSize: 'all', simple: 'Y' })
        ])
        console.log(billsResponse)
        this.solicitorOptions = solicitorsResponse.data.map(user => ({
          value: user.id,
          name: user.name || user.email
        }))
        this.mattersOptions = mattersResponse.data.matters.map(matter => ({
          value: matter.id,
          name: matter.name
        }))
        this.practiceOptions = practiceResponse.data.map(practice => ({
          value: practice.id,
          name: practice.name
        }))
        this.invoiceOptions = billsResponse.data.bills.map(invoice => ({
          value: invoice.id,
          name: invoice.name
        }))

        const elementResponse = await getActivityDetails(this.companyId, this.elementId)
        this.form = { ...this.form, ...elementResponse.data }

        this.typesOptions = [
          { value: '131', name: 'Phone call' },
          { value: '132', name: 'Meeting' },
          { value: '133', name: 'Case study' },
          { value: '134', name: 'Consolation' },
          { value: '135', name: 'Present in a court' },
          { value: '136', name: 'Present in other' },
          { value: '137', name: 'Filing case' },
          { value: '149', name: 'Other' }
        ]
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Activities', path: `/companies/${this.companyId}/activities` },
        { name: `Activity #${this.elementId}`, path: '' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
