import axios from 'axios'
import i18n from '../i18n'

// Функция для получения baseURL в зависимости от текущей локализации
const getBaseUrl = () => {
  const locale = i18n.global.locale.value || 'en' // по умолчанию, если locale не определен
  return `https://crm.pensli.com/api/v1.0/${locale}/`
}

const apiClient = axios.create({
  baseURL: getBaseUrl(), // Инициализация с динамическим baseURL
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

// Использование интерсептора для обновления baseURL перед каждым запросом
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken')
  config.baseURL = getBaseUrl()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['x-api-key'] = process.env.VUE_APP_API_KEY
  return config
})

export default apiClient
