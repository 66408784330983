import apiClient from './apiClient'

export const getEventsLog = async (params) => {
  try {
    const response = await apiClient.get('/events_log', {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching logs:', error)
    throw error
  }
}
