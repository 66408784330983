<template>
  <div class="main__header">
    <h1>{{ $t('dashboard.hello') }}, {{ user?.name }}</h1>
    <div class="search__container">
      <!-- <div class="search__input">
        <input type="text" name="globalSearch" :placeholder="$t('dashboard.search.placeholder')" />
      </div>
      <button>{{ $t('dashboard.search.button') }}</button> -->
    </div>
    <div class="topControl__container">
      <div @click="refreshDashboard" class="topControl__item">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.9331 13.041C19.7442 14.481 19.167 15.8424 18.2632 16.9792C17.3595 18.116 16.1633 18.9854 14.803 19.494C13.4427 20.0027 11.9696 20.1315 10.5417 19.8666C9.1138 19.6017 7.78492 18.9531 6.69761 17.9903C5.6103 17.0276 4.80557 15.787 4.36973 14.4017C3.93389 13.0163 3.88338 11.5385 4.22362 10.1266C4.56385 8.71473 5.282 7.42211 6.30104 6.38737C7.32007 5.35263 8.60156 4.6148 10.0081 4.25301C13.9071 3.25301 17.9431 5.26001 19.4331 9.00001"
            stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M20 4V9H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div @click="openNotification" class="topControl__item">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.99993 9C4.99993 7.14348 5.73743 5.36301 7.05018 4.05025C8.36294 2.7375 10.1434 2 11.9999 2C13.8564 2 15.6369 2.7375 16.9497 4.05025C18.2624 5.36301 18.9999 7.14348 18.9999 9V12.764L20.8219 16.408C20.9058 16.5757 20.9454 16.7621 20.937 16.9494C20.9286 17.1368 20.8724 17.3188 20.7738 17.4783C20.6752 17.6379 20.5375 17.7695 20.3737 17.8608C20.2099 17.9521 20.0255 18 19.8379 18H15.8739C15.6515 18.8582 15.1504 19.6183 14.4492 20.1609C13.748 20.7035 12.8865 20.9979 11.9999 20.9979C11.1133 20.9979 10.2518 20.7035 9.55067 20.1609C8.8495 19.6183 8.34837 18.8582 8.12593 18H4.16193C3.97441 18 3.78999 17.9521 3.6262 17.8608C3.4624 17.7695 3.32467 17.6379 3.22608 17.4783C3.12748 17.3188 3.07131 17.1368 3.06288 16.9494C3.05445 16.7621 3.09406 16.5757 3.17793 16.408L4.99993 12.764V9ZM10.2679 18C10.4435 18.304 10.6959 18.5565 11 18.732C11.304 18.9075 11.6489 18.9999 11.9999 18.9999C12.351 18.9999 12.6959 18.9075 12.9999 18.732C13.3039 18.5565 13.5564 18.304 13.7319 18H10.2679ZM11.9999 4C10.6738 4 9.40208 4.52678 8.4644 5.46447C7.52671 6.40215 6.99993 7.67392 6.99993 9V12.764C6.99991 13.0743 6.92767 13.3804 6.78893 13.658L5.61893 16H18.3819L17.2119 13.658C17.0729 13.3805 17.0003 13.0744 16.9999 12.764V9C16.9999 7.67392 16.4731 6.40215 15.5355 5.46447C14.5978 4.52678 13.326 4 11.9999 4Z"
            fill="black" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user'])
  },
  methods: {
    refreshDashboard () {
      this.$router.push({ path: '/dashboard', query: { refresh: Date.now() } })
    },
    openNotification () {
      // Open the notification panel
      console.log('Notification panel opened')
    }
  }
}
</script>

<style scoped>
.main__header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topControl__container {
  display: flex;
  gap: 16px;
}

.topControl__item {
  border-radius: 8px;
  padding: 8px;
  width: 36px;
  height: 36px;
  background-color: #ffffff;
}

.topControl__item:hover {
  cursor: pointer;
  opacity: 0.6;
}

.search__container {
  display: flex;
  gap: 24px;
  margin-left: auto;
  margin-right: 32px;
}

.search__container button{
  max-width: 160px;
  width: 100%;
  height: 36px;
}

.search__input {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 328px;
}

.search__input input {
  display: flex;
  align-items: center;
  justify-content: start;
  border: none;
  height: 100%;
  width: 100%;
  outline: none !important;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 111%;
  padding: 6px;
}

.search__input::before {
  content: '';
  background: url('../../assets/search.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  padding: 6px 8px;
}
</style>
