<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit event #{{ elementId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('event.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <CustomCheckbox id="active" :label="$t('event.active')" v-model="form.status" />
                        <CustomInput id="name" :label="$t('event.title')" v-model="form.name" />
                        <CustomDate id="start" :label="$t('event.start')" :type="'datetime'" :format="'MM/DD/YYYY HH:mm:ss'" v-model:modelValue="form.start" />
                        <CustomDate id="end" :label="$t('event.end')" :type="'datetime'" :format="'MM/DD/YYYY HH:mm:ss'" v-model:modelValue="form.end" />
                        <CustomTextarea id="description" :label="$t('event.description')" v-model="form.description" />
                        <CustomMultiselect id="assignee" :label="$t('event.assignee')" :searchable="true" v-model="form.assignee" :options="solicitorOptions" />
                        <CustomSelect id="matter" :label="$t('event.matter')" :searchable="true" v-model="form.matter" :options="mattersOptions" />
                        <CustomInput id="location" :label="$t('event.location')" v-model="form.location" />
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveElement" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyMembers, getEventDetails, getCompanyDetails, saveEventDetails, getMatters } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomInput from '@/components/form/CustomInput.vue'
import CustomTextarea from '@/components/form/CustomTextarea.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomDate from '@/components/form/CustomDate.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'
import CustomMultiselect from '@/components/form/CustomMultiselect.vue'

export default {
  components: {
    Breadcrumbs,
    CustomInput,
    CustomTextarea,
    CustomSelect,
    CustomDate,
    CustomCheckbox,
    CustomMultiselect
  },
  data () {
    return {
      dateFrom: '',
      solicitorOptions: [],
      mattersOptions: [],
      form: {
        name: ''
      },
      loading: false,
      company: null,
      companyId: null,
      elementId: null,
      isSaving: false,
      message: null
    }
  },
  created () {
    this.companyId = this.$route.params.companyId
    this.elementId = this.$route.params.eventId
    this.loadInitialData()
  },
  methods: {
    async saveElement () {
      this.isSaving = true
      this.message = null

      const formData = new FormData()

      // Основные поля
      console.log('this.form.date: ', this.form.date)
      formData.append('name', this.form.name)
      formData.append('date_start', this.form.start)
      formData.append('date_end', this.form.end)
      formData.append('description', this.form.description)
      formData.append('location', this.form.location)
      formData.append('matter', this.form.matter?.id)

      this.form.assignee?.forEach(assignee => {
        formData.append('assignee[]', assignee.id)
      })

      try {
        const response = await saveEventDetails(this.companyId, this.elementId, formData)

        if (response.status === 'Success') {
          this.message = 'Event saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        const companyResponse = await getCompanyDetails(this.companyId)
        this.company = companyResponse.data
        this.updateBreadcrumbs()

        const [solicitorsResponse, mattersResponse] = await Promise.all([
          getCompanyMembers(this.companyId),
          getMatters(this.companyId, { pageSize: 'all' })
        ])

        this.solicitorOptions = solicitorsResponse.data.map(user => ({
          value: user.id,
          name: user.name || user.email
        }))
        this.mattersOptions = mattersResponse.data.matters.map(matter => ({
          value: matter.id,
          name: matter.name
        }))

        const elementResponse = await getEventDetails(this.companyId, this.elementId)
        this.form = { ...this.form, ...elementResponse.data }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Events', path: `/companies/${this.companyId}/events` },
        { name: `Event #${this.elementId}`, path: '' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
