<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit task #{{ elementId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('task.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <CustomCheckbox id="active" :label="$t('task.active')" v-model="form.status" />
                        <CustomInput id="name" :label="$t('task.name')" v-model="form.name" />
                        <CustomCheckbox id="high_priority" :label="$t('task.high_priority')"
                            v-model="form.high_priority" />
                        <CustomTextarea id="description" :label="$t('task.description')" v-model="form.description" />
                        <CustomMultiselect id="assignee" :label="$t('task.assigned_to')"
                            :searchable="true" v-model="form.assignee" :options="solicitorOptions" />
                        <CustomSelect id="status" :label="$t('task.status')"
                            :searchable="true" v-model="form.completed" :options="statusOptions" />
                        <CustomSelect id="matter" :label="$t('task.matter')"
                            :searchable="true" v-model="form.matter" :options="mattersOptions" />
                        <CustomDate id="deadline" :label="$t('task.deadline')" v-model:modelValue="form.deadline" />
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveElement" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyMembers, getTaskDetails, getCompanyDetails, saveTaskDetails, getMatters } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomInput from '@/components/form/CustomInput.vue'
import CustomTextarea from '@/components/form/CustomTextarea.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomMultiselect from '@/components/form/CustomMultiselect.vue'
import CustomDate from '@/components/form/CustomDate.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'

export default {
  components: {
    Breadcrumbs,
    CustomInput,
    CustomTextarea,
    CustomSelect,
    CustomMultiselect,
    CustomDate,
    CustomCheckbox
  },
  data () {
    return {
      dateFrom: '',
      statusOptions: [],
      solicitorOptions: [],
      clientOptions: [],
      form: {
        name: ''
      },
      loading: false,
      company: null,
      companyId: null,
      elementId: null,
      isSaving: false,
      message: null
    }
  },
  created () {
    this.companyId = this.$route.params.companyId
    this.elementId = this.$route.params.taskId
    this.loadInitialData()
  },
  computed: {
    selectedStatus () {
      return this.statusOptions.find(option => option.value === this.form.completed)
    }
  },
  methods: {
    async saveElement () {
      this.isSaving = true
      this.message = null

      const formData = new FormData()

      // Основные поля
      formData.append('name', this.form.name)
      formData.append('description', this.form.description)
      //   formData.append('practice_area', this.form.practice_area)
      formData.append('status', this.form.completed)
      formData.append('status_active', this.form.status)
      formData.append('matter', this.form.matter?.id)
      formData.append('deadline', this.form.deadline)
      formData.append('high_priority', this.form.high_priority)

      // Массивы для assignee
      this.form.assignee?.forEach(assignee => {
        formData.append('assignee[]', assignee.id)
      })

      // Поля для напоминаний
      this.form.reminder_task_users?.forEach(user => {
        formData.append('reminder_task_users[]', user)
      })
      this.form.reminder_task_time?.forEach(time => {
        formData.append('reminder_task_time[]', time)
      })
      this.form.reminder_task_type?.forEach(type => {
        formData.append('reminder_task_type[]', type)
      })

      // Типы уведомлений
      this.form.notification_type?.forEach(type => {
        formData.append('notification_type[]', type)
      })

      // Для файлов, если имеются
      if (this.form.files && this.form.files.length > 0) {
        this.form.files.forEach(file => {
          formData.append('file[]', file)
        })
      }

      try {
        const response = await saveTaskDetails(this.companyId, this.elementId, formData)
        console.log('response: ', response)
        if (response.status === 'Success') {
          this.message = 'Task saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        const companyResponse = await getCompanyDetails(this.companyId)
        this.company = companyResponse.data
        this.updateBreadcrumbs()

        const [solicitorsResponse, mattersResponse] = await Promise.all([
          getCompanyMembers(this.companyId),
          getMatters(this.companyId, { pageSize: 'all' })
        ])

        this.solicitorOptions = solicitorsResponse.data.map(user => ({
          value: user.id,
          name: user.name || user.email
        }))
        this.mattersOptions = mattersResponse.data.matters.map(matter => ({
          value: matter.id,
          name: matter.name
        }))

        const elementResponse = await getTaskDetails(this.companyId, this.elementId)
        this.form = { ...this.form, ...elementResponse.data }

        this.statusOptions = [
          { value: false, name: 'Pending' },
          { value: true, name: 'Comleted' }
        ]
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Tasks', path: `/companies/${this.companyId}/tasks` },
        { name: `Task #${this.elementId}`, path: '' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
