import apiClient from './apiClient'

export const login = async (email, password) => {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('password', password)
  formData.append('superadmin', 'Y')

  try {
    const response = await apiClient.post('/auth/', formData)
    console.log('Login request data:', formData.get('email'), formData.get('password')) // Логирование данных запроса
    return response
  } catch (error) {
    console.error('Login error:', error)
    throw error
  }
}

export const logout = async (token) => {
  try {
    const response = await apiClient.post('/logout/', null, {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-api-key': process.env.VUE_APP_API_KEY
      }
    })
    return response
  } catch (error) {
    console.error('Logout error:', error)
    throw error
  }
}
