<template>
    <div class="modal__overlay" @click.self="close">
        <div class="modal__content">
            <div class="modal__header">
                <h2>{{ $t('clients.sendMessageModal.title') }}</h2>
                <div class="modal__close" @click="close">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L12.94 1M13 13L1.06 1" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <form @submit.prevent="sendMessage">
                <div class="modal__formGroup">
                    <textarea v-model="message" :placeholder="$t('clients.sendMessageModal.enterMessage')"></textarea>
                </div>
                <div class="modal__formGroup">
                    <label for="chooseUsers">{{ $t('clients.sendMessageModal.chooseUsers') }}</label>
                    <Multiselect v-model="selectedUsers" mode="tags" :close-on-select="false" :searchable="true"
                        :create-option="true" :options="users" label="name" placeholder="Select users" :disabled="sendByFilter"/>
                </div>
                <div class="flex flex-col g-16">
                    <div class="modal__formSubGroup">
                        <label for="sendByFilter">
                            <input type="checkbox" v-model="sendByFilter" id="sendByFilter">
                            {{ $t('clients.sendMessageModal.sendByFilter') }}
                        </label>
                    </div>
                    <div class="flex flex-row g-16" :class="{ 'is-disabled': !sendByFilter }">
                        <div class="modal__formSubGroup">
                            <label for="bySubscription">{{ $t('clients.sendMessageModal.bySubscription') }}</label>
                            <Multiselect v-model="selectedSubscriptions" mode="tags" :close-on-select="false"
                                :searchable="false" :create-option="true" :options="subscriptions" label="name"
                                placeholder="Select subscriptions" :disabled="!sendByFilter || selectedLastLogins.length > 0" />
                        </div>
                        <div class="modal__formSubGroup">
                            <label for="byLastLogin">{{ $t('clients.sendMessageModal.byLastLogin') }}</label>
                            <Multiselect v-model="selectedLastLogins" :close-on-select="false" :searchable="false"
                                :create-option="true" :options="lastLogins" label="name" placeholder="Select periods"
                                :disabled="!sendByFilter || selectedSubscriptions.length > 0" />
                        </div>
                    </div>
                </div>
                <button class="w-100" type="submit">{{ $t('clients.sendMessageModal.send') }}</button>
            </form>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import { getClients, getSubscriptions, sendMessage } from '@/api'
import { toRaw } from 'vue'

export default {
  components: { Multiselect },
  data () {
    return {
      message: '',
      users: [],
      subscriptions: [],
      lastLogins: [],
      selectedUsers: [],
      selectedSubscriptions: [],
      selectedLastLogins: '',
      sendByFilter: false
    }
  },
  props: {
    selectedUserId: {
      type: Number,
      default: null
    }
  },
  mounted () {
    this.fetchData()
    if (this.selectedUserId) {
      this.preselectUser()
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.resetModal() // Reset or clear the modal's data
    },
    resetModal () {
      this.message = ''
      this.selectedUsers = []
      this.selectedSubscriptions = []
      this.selectedLastLogins = ''
      this.sendByFilter = false
    },
    async preselectUser () {
      const user = this.users.find(u => u.value === this.selectedUserId)
      if (user) {
        this.selectedUsers = [user.value]
        console.log('this.selectedUsers: ', this.selectedUsers)
      }
    },
    async sendMessage () {
      const data = {
        message: this.message,
        users: toRaw(this.selectedUsers),
        filters: this.sendByFilter
          ? {
              subscriptions: toRaw(this.selectedSubscriptions),
              lastLogins: this.selectedLastLogins
            }
          : null
      }
      try {
        await sendMessage(data)
        console.log('Message sent:', this.message)
        this.close()
      } catch (error) {
        console.error('Error sending message:', error)
      }
    },
    async fetchData () {
      try {
        const [users, subscriptions] = await Promise.all([
          getClients({ pageSize: 'all' }),
          getSubscriptions()
        ])
        this.users = users.data.clients.map(user => ({
          value: user.id,
          name: user.name || user.email
        }))
        this.subscriptions = subscriptions.data.plans.map(plan => ({
          value: plan.id,
          name: plan.name + ' (' + plan.coast + ' ريال)'
        }))
        this.lastLogins = [
          { value: 'day', name: this.$t('clients.sendMessageModal.lastLoginPeriods.day') },
          { value: 'week', name: this.$t('clients.sendMessageModal.lastLoginPeriods.week') },
          { value: 'month', name: this.$t('clients.sendMessageModal.lastLoginPeriods.month') },
          { value: 'quarter', name: this.$t('clients.sendMessageModal.lastLoginPeriods.quarter') },
          { value: 'year', name: this.$t('clients.sendMessageModal.lastLoginPeriods.year') }
        ]
        if (this.selectedUserId) {
          this.preselectUser()
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }
}
</script>

<style scoped>
button.w-100 {
    width: 100%;
}
</style>
