<template>
  <svg style="position: absolute; top: -10000px; left: -10000px;">
    <defs id="svg-defs">
      <!-- Icon User -->
      <symbol id="form_one_user" width="18" height="20" viewBox="0 0 18 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9 11C11.7614 11 14 8.76142 14 6C14 3.23858 11.7614 1 9 1C6.23858 1 4 3.23858 4 6C4 8.76142 6.23858 11 9 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17 19C17 16.8783 16.1571 14.8434 14.6569 13.3431C13.1566 11.8429 11.1217 11 9 11C6.87827 11 4.84344 11.8429 3.34315 13.3431C1.84285 14.8434 1 16.8783 1 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </symbol>
      <!-- Icon Password -->
      <symbol id="form_password" width="16" height="21" viewBox="0 0 16 21" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M2 21C1.45 21 0.979333 20.8043 0.588 20.413C0.196666 20.0217 0.000666667 19.5507 0 19V9C0 8.45 0.196 7.97933 0.588 7.588C0.98 7.19667 1.45067 7.00067 2 7H3V5C3 3.61667 3.48767 2.43767 4.463 1.463C5.43833 0.488334 6.61733 0.000667349 8 6.82594e-07C9.38267 -0.000665984 10.562 0.487001 11.538 1.463C12.514 2.439 13.0013 3.618 13 5V7H14C14.55 7 15.021 7.196 15.413 7.588C15.805 7.98 16.0007 8.45067 16 9V19C16 19.55 15.8043 20.021 15.413 20.413C15.0217 20.805 14.5507 21.0007 14 21H2ZM2 19H14V9H2V19ZM8 16C8.55 16 9.021 15.8043 9.413 15.413C9.805 15.0217 10.0007 14.5507 10 14C9.99933 13.4493 9.80367 12.9787 9.413 12.588C9.02233 12.1973 8.55133 12.0013 8 12C7.44867 11.9987 6.978 12.1947 6.588 12.588C6.198 12.9813 6.002 13.452 6 14C5.998 14.548 6.194 15.019 6.588 15.413C6.982 15.807 7.45267 16.0027 8 16ZM5 7H11V5C11 4.16667 10.7083 3.45833 10.125 2.875C9.54167 2.29167 8.83333 2 8 2C7.16667 2 6.45833 2.29167 5.875 2.875C5.29167 3.45833 5 4.16667 5 5V7Z" fill="black" />
      </symbol>
      <!-- Main Logo -->
      <symbol id="main_logo" width="89" height="95" viewBox="0 0 89 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M67.5815 59.209C68.6557 59.8379 69.874 60.1523 71.2365 60.1523H71.3109H72.6513H73.1973H76.7344H80.861C82.4069 60.1523 83.7824 59.7855 84.9877 59.0518C86.1929 58.292 87.1361 57.2047 87.8174 55.7898C88.4986 54.375 88.8392 52.672 88.8392 50.6807C88.8392 47.72 88.0401 45.4143 86.4418 43.7637C84.8436 42.113 82.682 41.2877 79.9571 41.2877C77.9659 41.2877 76.3414 41.6938 75.0838 42.506C73.8261 43.3183 73.1973 44.3663 73.1973 45.6501V57.4012H72.6513H71.3109H71.2365C70.4505 57.4012 69.7954 57.1654 69.2714 56.6938C68.7474 56.2222 68.3544 55.6326 68.0924 54.9252C67.8566 54.2178 67.7387 53.4973 67.7387 52.7637V51.7025C67.7387 53.7986 67.4112 55.4754 66.7562 56.7331C66.5317 57.1551 66.2825 57.5356 66.0088 57.8746C66.4609 58.3915 66.9851 58.8363 67.5815 59.209ZM83.8479 55.6719C82.8785 56.8248 81.4899 57.4012 79.682 57.4012H76.7344V45.6501C76.7344 45.1523 76.9833 44.7593 77.4811 44.4711C78.0052 44.1829 78.8305 44.0388 79.9571 44.0388C81.6864 44.0388 83.0095 44.6021 83.9265 45.7287C84.8436 46.8292 85.3021 48.4798 85.3021 50.6807C85.3021 52.8554 84.8174 54.5191 83.8479 55.6719Z" fill="#5374D1" />
        <path d="M45.2254 59.327C46.2472 59.8772 47.387 60.1523 48.6446 60.1523H48.7168H50.0594H60.7037C61.9613 60.1523 63.1141 59.8903 64.1622 59.3663C64.8682 59.0218 65.4837 58.5246 66.0087 57.8746C66.2824 57.5356 66.5316 57.1551 66.7561 56.7331C67.4111 55.4754 67.7386 53.7986 67.7386 51.7025C67.7386 50.1305 67.5159 48.7025 67.0705 47.4187C66.6513 46.1349 65.9831 45.0344 65.0661 44.1174C64.1753 43.2004 63.0093 42.506 61.5683 42.0344C60.1273 41.5366 58.398 41.2877 56.3805 41.2877C55.8565 41.2877 55.3718 41.3008 54.9264 41.327C54.481 41.3532 54.0749 41.3794 53.7081 41.4056C53.3675 41.4318 53.0661 41.4449 52.8041 41.4449C52.0443 41.4449 51.4417 41.327 50.9963 41.0912C50.5509 40.8554 50.1972 40.6458 49.9352 40.4624C49.909 40.5672 49.909 40.7768 49.9352 41.0912L50.0924 41.9558C50.1972 42.4012 50.3413 42.8074 50.5247 43.1742C50.7343 43.5148 51.0356 43.7768 51.4286 43.9602C51.8216 44.1436 52.3325 44.2353 52.9613 44.2353C53.2758 44.2353 53.6033 44.2222 53.9439 44.196C54.2845 44.1436 54.6644 44.1043 55.0836 44.0781C55.5028 44.0519 55.9744 44.0388 56.4985 44.0388C58.9875 44.0388 60.8871 44.6021 62.1971 45.7287C63.5334 46.8554 64.2015 48.8597 64.2015 51.7418C64.2015 53.8117 63.8871 55.2789 63.2583 56.1436C62.6294 56.982 61.7779 57.4012 60.7037 57.4012H50.0594H48.7168H48.6053C47.5573 57.4012 46.7581 57.0213 46.2079 56.2615C45.6938 55.5515 45.3627 54.7499 45.2146 53.8569C45.2218 53.9885 45.2254 54.1219 45.2254 54.2571C45.2254 55.3837 44.9634 56.3925 44.4394 57.2833C44.2388 57.6242 44.0076 57.9383 43.7456 58.2255C44.1831 58.6564 44.6764 59.0235 45.2254 59.327Z" fill="#5374D1" />
        <path d="M1.44612 70.9994C1.68193 71.0518 1.91773 71.078 2.15354 71.078C2.65135 71.078 3.13607 70.947 3.60768 70.685C4.0793 70.4492 4.47231 70.0038 4.78672 69.3488C5.10113 68.7199 5.25833 67.8422 5.25833 66.7156V64.2003C5.25833 63.5453 5.38934 62.9034 5.65134 62.2745C5.93955 61.6719 6.41117 61.161 7.06619 60.7418C7.72121 60.3488 8.63823 60.1523 9.81727 60.1523H12.8434C14.3893 60.1523 15.7648 59.7855 16.97 59.0518C17.648 58.6245 18.243 58.0934 18.7551 57.4588C19.1535 56.9652 19.5017 56.4089 19.7997 55.7898C20.1616 55.0382 20.4274 54.2051 20.5971 53.2907C20.7417 52.5111 20.8164 51.6724 20.8213 50.7745C20.8215 50.7433 20.8215 50.712 20.8215 50.6807C20.8215 47.72 20.0224 45.4143 18.4242 43.7637C16.8259 42.113 14.6644 41.2877 11.9395 41.2877C9.94827 41.2877 8.32382 41.7069 7.06619 42.5453C5.80855 43.3576 5.17973 44.3925 5.17973 45.6501V58.4585C5.30748 58.3913 5.43849 58.3271 5.57274 58.2658C6.51369 57.8254 7.56172 57.5532 8.71684 57.4493V45.6501C8.71684 45.1523 8.96574 44.7593 9.46356 44.4711C9.98757 44.1829 10.8128 44.0388 11.9395 44.0388C13.6687 44.0388 14.9919 44.6021 15.9089 45.7287C16.8259 46.8292 17.2844 48.4798 17.2844 50.6807C17.2844 52.8554 16.7997 54.5191 15.8303 55.6719C14.8609 56.8248 13.4722 57.4012 11.6644 57.4012H9.81727C9.44025 57.4012 9.07344 57.4172 8.71684 57.4493C7.56172 57.5532 6.51369 57.8254 5.57274 58.2658C5.43849 58.3271 5.30748 58.3913 5.17973 58.4585C4.16062 58.9941 3.3484 59.7159 2.74306 60.6239C2.06184 61.6457 1.72123 62.8379 1.72123 64.2003V66.7156C1.72123 67.3444 1.64262 67.9077 1.48542 68.4055C1.32821 68.9033 1.13171 69.3226 0.895898 69.6632C0.686292 70.03 0.450485 70.3313 0.188477 70.5671C0.371882 70.6457 0.568388 70.7243 0.777994 70.8029C0.987601 70.8815 1.21031 70.947 1.44612 70.9994Z" fill="#5374D1" />
        <path d="M20.6643 59.209C21.7386 59.8379 22.9569 60.1523 24.3194 60.1523H24.3959H25.7342H39.0159C40.2473 60.1523 41.3216 59.9034 42.2386 59.4056C42.8208 59.0821 43.3232 58.6887 43.7457 58.2255C44.0077 57.9383 44.2389 57.6242 44.4395 57.2833C44.9635 56.3925 45.2255 55.3837 45.2255 54.2571C45.2255 54.1219 45.2219 53.9885 45.2147 53.8569C45.1604 52.8664 44.902 51.978 44.4395 51.1916C43.9155 50.3008 43.1818 49.6065 42.2386 49.1086C41.3216 48.5846 40.2473 48.3226 39.0159 48.3226H32.5312C31.4308 48.3226 30.5269 47.9951 29.8194 47.3401C29.1382 46.6589 28.7976 45.8204 28.7976 44.8248C28.7976 43.803 29.1382 42.9646 29.8194 42.3095C30.5269 41.6283 31.4308 41.2877 32.5312 41.2877H41.9635C42.4875 41.2877 42.9329 41.1567 43.2997 40.8947C43.6666 40.6327 43.9548 40.3052 44.1644 39.9122C44.4002 39.5192 44.5574 39.113 44.636 38.6938C44.7146 38.2746 44.7277 37.9078 44.6753 37.5934C44.4395 37.7768 44.0989 37.9864 43.6535 38.2222C43.2342 38.4318 42.6578 38.5366 41.9242 38.5366H32.5312C31.1688 38.5366 29.9373 38.8117 28.8369 39.362C27.7365 39.9122 26.8587 40.6589 26.2037 41.6021C25.5749 42.5453 25.2605 43.6196 25.2605 44.8248C25.2605 46.0039 25.5749 47.065 26.2037 48.0082C26.8587 48.9514 27.7365 49.6982 28.8369 50.2484C29.9373 50.7986 31.1688 51.0737 32.5312 51.0737H39.0159C39.8543 51.0737 40.5094 51.3488 40.981 51.899C41.4526 52.4492 41.6884 53.2353 41.6884 54.2571C41.6884 55.2527 41.4526 56.0257 40.981 56.5759C40.5094 57.1261 39.8543 57.4012 39.0159 57.4012H25.7342H24.3959H24.3194C23.5333 57.4012 22.8783 57.1654 22.3543 56.6938C21.8303 56.2222 21.4373 55.6326 21.1753 54.9252C20.9395 54.2178 20.8215 53.4973 20.8215 52.7637L20.8213 50.7745L20.8215 50.7593C20.8215 50.7593 20.8215 50.7645 20.8213 50.7745C20.8164 51.6724 20.7417 52.5111 20.5971 53.2907C20.4996 53.8823 20.3602 54.4714 20.1608 54.9252C20.0535 55.2245 19.9332 55.5127 19.7997 55.7898C19.5017 56.4089 19.1535 56.9652 18.7551 57.4588C19.2773 58.1565 19.9137 58.7399 20.6643 59.209Z" fill="#5374D1" />
        <path d="M26.8427 29.9797H21.9045L37.6175 24.0531L45.0001 4.25174L52.3827 24.0531L68.0957 29.9797H63.1574L51.0264 25.4041L45.0001 9.24044L38.9737 25.4041L26.8427 29.9797Z" fill="#5374D1" />
        <path d="M24.3733 65.0202L38.2954 70.2714L44.9999 88.2539L51.7043 70.2714L65.6264 65.0203H60.6881L50.3481 68.9203L44.9999 83.2652L39.6516 68.9203L29.3116 65.0202H24.3733Z" fill="#5374D1" />
      </symbol>
    </defs>
  </svg>
  <router-view v-slot="{ Component }">
      <SideMenu v-if="shouldShowMenu" />
      <component :is="Component" />
  </router-view>
</template>
<script>
import SideMenu from './components/SideMenu.vue'
import { mapState } from 'vuex'

export default {
  components: {
    SideMenu
  },
  computed: {
    ...mapState(['user']),
    shouldShowMenu () {
      return this.$route.name !== 'login'
    }
  },
  created () {
    this.clientId = this.$route.params.userId
    this.connectToSocket()
  },
  methods: {
    connectToSocket () {
      this.$socket.on('connect', () => {
        console.log('Connected to the chat server!')
        this.$socket.emit('updateChatList')
        this.$socket.emit('join', {
          user_id: parseInt(this.user.id)
        })
        this.$socket.emit('updateUnreadCount', { userId: parseInt(this.user.id) })
      })

      this.$socket.on('error', (error) => {
        console.log('Socket error:', error)
      })

      this.$socket.on('connect_error', (error) => {
        console.log('Connection error:', error)
      })

      this.$socket.on('disconnect', () => {
        console.log('Disconnected from the chat server')
      })

      this.$socket.on('newMessage', (data) => {
        this.$socket.emit('updateUnreadCount', { userId: parseInt(this.user.id) })
      })
    }
  }
}
</script>
<style>
#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  display: flex;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>
