<template>
    <div class="main__container">
        <div class="flex j-btw">
            <h1>{{ $t('history.title') }}</h1>
        </div>
        <div class="clients__container">
            <Suspense>
                <template #default>
                    <div class="table__container">
                        <div class="table__filter">
                            <div class="table__search"></div>
                            <div class="table__filterForm">
                                <form @submit.prevent="applyFilter" @reset="resetFilter">
                                    <label>{{ $t('history.time') }}:</label>
                                    <DatePicker v-model:value="dateFrom"
                                        :placeholder="$t('companies.dateFromPlaceholder')"
                                        format="YYYY-MM-DD" />
                                    <DatePicker v-model:value="dateTo" :placeholder="$t('companies.dateToPlaceholder')"
                                        format="YYYY-MM-DD" />
                                    <button type="submit">{{ $t('companies.apply') }}</button>
                                    <button type="reset">{{ $t('companies.reset') }}</button>
                                </form>
                            </div>
                        </div>
                        <div class="adminTable">
                            <div class="adminTable__header">
                                <div class="adminTable__col w-5">#</div>
                                <div class="adminTable__col w-5" @click="sortTable('id')"
                                    :class="{ active: sortKey === 'id' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('history.id') }}
                                </div>
                                <div class="adminTable__col w-15" @click="sortTable('time')"
                                    :class="{ active: sortKey === 'time' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('history.time') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('event')"
                                    :class="{ active: sortKey === 'event' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('history.event') }}
                                </div>
                                <div class="adminTable__col w-10" @click="sortTable('user')"
                                    :class="{ active: sortKey === 'user' }">
                                    <div class="adminTable__colOrder"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.29289 1.70711C7.68342 1.31658 8.31658 1.31658 8.70711 1.70711L12.2929 5.29289C12.9229 5.92286 12.4767 7 11.5858 7H4.41421C3.52331 7 3.07714 5.92286 3.70711 5.29289L7.29289 1.70711Z"
                                                fill="#D9D9D9" />
                                            <path
                                                d="M8.70711 14.2929C8.31658 14.6834 7.68342 14.6834 7.29289 14.2929L3.70711 10.7071C3.07714 10.0771 3.52331 9 4.41421 9L11.5858 9C12.4767 9 12.9229 10.0771 12.2929 10.7071L8.70711 14.2929Z"
                                                fill="#D9D9D9" />
                                        </svg></div>{{ $t('history.user') }}
                                </div>
                                <div class="adminTable__col w-20">{{ $t('history.description') }}
                                </div>
                                <div class="adminTable__col w-20">{{ $t('history.userAgent') }}</div>
                            </div>
                            <div v-if="loading" class="adminTable__body">
                              <div v-for="n in 10" :key="n" class="adminTable__row shimmer-table-row">
                                <div class="adminTable__col w-5 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-20 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-10 shimmer shimmer-box"></div>
                                <div class="adminTable__col w-5 shimmer shimmer-box"></div>
                              </div>
                            </div>
                            <div v-else class="adminTable__body">
                                <div v-for="log in eventsLogs" :key="log.id" class="adminTable__row">
                                    <div class="adminTable__col w-5"><input type="checkbox" name="selectedCompany"
                                            :value="log.id"></div>
                                    <div class="adminTable__col w-5">{{ log.id }}</div>
                                    <div class="adminTable__col w-15" style="line-height: 36px;">{{ log.time }}</div>
                                    <div class="adminTable__col w-10">{{ log.event }}</div>
                                    <div class="adminTable__col w-10"><router-link v-if="log.user" :to="'/clients/' + log.user.id">{{ log.user.name }}</router-link></div>
                                    <div class="adminTable__col w-20">{{ log.description }}</div>
                                    <div class="adminTable__col w-20">{{ log.userAgent }}</div>
                                </div>
                            </div>
                            <div class="adminTable__footer">
                                <div class="adminTable__resultPerPage">{{ paginationInfo }}</div>
                                <div class="adminTable__navigation">
                                    <div class="adminTable__navPrev" :class="{ disabled: currentPage === 1 }"
                                        @click="prevPage">&lt;</div>
                                    <div v-for="page in generatePageNumbers()" :key="page" class="adminTable__navNum"
                                        :class="{ active: currentPage === page, ellipsis: page === '...' }"
                                        @click="page !== '...' && goToPage(page)">{{ page }}</div>
                                    <div class="adminTable__navNext" :class="{ disabled: currentPage === totalPages }"
                                        @click="nextPage">&gt;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #fallback>
                </template>
            </Suspense>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getEventsLog } from '@/api'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { format } from 'date-fns'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      eventsLogs: [],
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      pageSize: 10,
      totalCompanies: 0,
      loading: false,
      sortKey: '',
      sortOrder: 1,
      openMenuId: null,
      isModalOpen: false,
      selectedUserId: null
    }
  },
  computed: {
    ...mapState(['user']),
    paginationInfo () {
      return `${this.pageSize * (this.currentPage - 1) + 1}-${Math.min(this.pageSize * this.currentPage, this.totalCompanies)} ${this.$t('companies.table.resultText')} ${this.totalCompanies}`
    },
    totalPages () {
      return Math.ceil(this.totalCompanies / this.pageSize)
    }
  },
  methods: {
    formatDate (timestamp) {
      const date = new Date(timestamp * 1000)
      return format(date, 'MM/dd/yyyy HH:mm')
    },
    async fetchCompanies () {
      this.eventsLogs = [] // Clear the existing companies
      this.loading = true
      const params = {
        search: this.searchQuery,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: this.currentPage,
        pageSize: this.pageSize,
        sortKey: this.sortKey,
        sortOrder: this.sortOrder
      }
      try {
        const response = await getEventsLog(params)
        this.eventsLogs = response.data.logs.map(element => {
          return {
            ...element,
            time: this.formatDate(element.time)
          }
        })
        this.totalCompanies = response.data.total
      } catch (error) {
        console.error('Error fetching companies:', error)
      } finally {
        this.loading = false // Скрыть спиннер
      }
    },
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
      this.selectedUserId = null
    },
    openMenu (companyId) {
      this.openMenuId = this.openMenuId === companyId ? null : companyId
    },
    editCompany (companyId) {
      console.log('Edit user with ID:', companyId)
      // Add your logic for editing a user
      this.openMenuId = null
    },
    addNote (companyId) {
      console.log('Add note for user with ID:', companyId)
      // Add your logic for adding a note
      this.openMenuId = null
    },
    writeMessage (companyId) {
      this.selectedUserId = companyId
      this.openModal()
      this.openMenuId = null
    },
    sortTable (key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1 // Toggle sort order
      } else {
        this.sortKey = key
        this.sortOrder = 1 // Default to ascending
      }
      this.fetchCompanies()
    },
    applyFilter () {
      this.currentPage = 1
      this.fetchCompanies()
    },
    resetFilter () {
      this.searchQuery = ''
      this.dateFrom = ''
      this.dateTo = ''
      this.currentPage = 1
      this.fetchCompanies()
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fetchCompanies()
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.fetchCompanies()
      }
    },
    goToPage (page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page
        this.fetchCompanies()
      }
    },
    generatePageNumbers () {
      const pages = []
      const totalPages = this.totalPages
      const currentPage = this.currentPage

      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i)
        }
      } else {
        if (currentPage <= 4) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        } else if (currentPage >= totalPages - 3) {
          pages.push(1)
          pages.push('...')
          for (let i = totalPages - 4; i <= totalPages; i++) {
            pages.push(i)
          }
        } else {
          pages.push(1)
          pages.push('...')
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        }
      }

      return pages
    }
  },
  mounted () {
    this.fetchCompanies()
  }
}
</script>

<style scoped>
.mainButton {
    padding: 8px 10px;
    height: 40px;
}

.clients__container {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    overflow: auto;
}
</style>
