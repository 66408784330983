<template>
  <div class="main__container">
    <Header />
    <div class="dashboard__container">
      <Suspense>
        <template #default>
          <div class="flex g-20 flex-col w-50">
            <div class="flex g-20">
              <CompaniesStats />
              <MattersStats />
            </div>
            <DonutChart />
          </div>
        </template>
        <template #fallback>
        </template>
      </Suspense>
      <div class="flex g-20 flex-col w-50">
        <AllUsersStats />
        <SubscriptionEndsSoon />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Header from '@/components/dashboard/DashboardHeader.vue'

const CompaniesStats = defineAsyncComponent(() => import('@/components/dashboard/CompaniesStats.vue'))
const MattersStats = defineAsyncComponent(() => import('@/components/dashboard/MattersStats.vue'))
const DonutChart = defineAsyncComponent(() => import('@/components/dashboard/DonutChart.vue'))
const AllUsersStats = defineAsyncComponent(() => import('@/components/dashboard/AllUsersStats.vue'))
const SubscriptionEndsSoon = defineAsyncComponent(() => import('@/components/dashboard/SubscriptionEndsSoon.vue'))

export default {
  components: {
    Header,
    CompaniesStats,
    MattersStats,
    DonutChart,
    AllUsersStats,
    SubscriptionEndsSoon
  }
}
</script>

<style scoped>
.dashboard__container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

</style>
