<template>
    <div class="main__container">
        <div class="flex flex-col j-btw g-16">
            <div class="flex g-16 a-center">
                <h1>Edit matter #{{ matterId }}</h1>
            </div>
            <Breadcrumbs />
        </div>
        <div v-if="loading" class="main__body">
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 160px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 80%; height: 40px;"></div>
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 100%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3 class="shimmer " style="width: 80%; height: 24px;"></h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                    <div class="flex w-50 g-24">
                        <div class="shimmer shimmer-box" style="width: 40%; height: 40px;"></div>
                        <div class="shimmer shimmer-box" style="width: 50%; height: 40px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main__body">
            <div class="block__box">
                <h3>{{ $t('matter.information') }}</h3>
                <div class="flex j-btw w-100 g-24">
                    <div class="flex flex-col w-50 g-24">
                        <CustomCheckbox id="active" :label="$t('matter.active')" v-model="form.status_active" />
                        <CustomInput id="name" :label="$t('matter.matterName')" v-model="form.name" />
                        <CustomSelect id="client" :label="$t('matter.client')" :searchable="true"
                            v-model="form.contact_details" :options="clientOptions" />
                        <CustomTextarea id="description" :label="$t('matter.description')" v-model="form.description" />
                        <CustomSelect id="responsible_solicitor" :label="$t('matter.responsibleSolicitor')"
                            :searchable="true" v-model="form.responsible_solicitor" :options="solicitorOptions" />
                        <CustomSelect id="originating_solicitor" :label="$t('matter.originatingSolicitor')"
                            :searchable="true" v-model="form.originating_solicitor" :options="solicitorOptions" />
                        <CustomInput id="matter_number" :label="$t('matter.matterNumber')"
                            v-model="form.client_refer_number" />
                        <CustomInput id="location" :label="$t('matter.location')" v-model="form.location" />
                    </div>
                    <div class="flex flex-col w-50 g-24">
                        <CustomMultiselect id="permissions" :label="$t('matter.permissions')" :mode="tags"
                            v-model="form.permissions" :options="solicitorOptions" />
                        <CustomSelect id="practice_area" :label="$t('matter.practiceArea')" v-model="form.practice_area"
                            :options="practiceOptions" />
                        <CustomSelect id="status" :label="$t('matter.status')" v-model="form.status"
                            :options="statusOptions" />
                        <CustomDate id="open_date" :label="$t('matter.openDate')" v-model:modelValue="form.open_date" />
                        <CustomDate id="close_date" :label="$t('matter.closeDate')"
                            v-model:modelValue="form.close_date" />
                        <CustomDate id="pending_date" :label="$t('matter.pendingDate')"
                            v-model:modelValue="form.pending_date" />
                        <CustomDate id="limit_date" :label="$t('matter.limitDate')"
                            v-model:modelValue="form.limit_date" />
                        <CustomCheckbox id="limit_date_satisfied" :label="$t('matter.limitDateSatisfied')"
                            v-model="form.limit_date_satisfied" />
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3>{{ $t('matter.relatedContacts') }}</h3>
                <div class="flex flex-col j-btw w-100 g-24">
                    <div v-for="(relationshipClient, index) in form.relationship_client" :key="relationshipClient.id"
                        class="flex w-50 g-24">
                        <CustomInput :label="$t('matter.relationship')"
                            v-model="form.relationship_client[index].relationship_description" />
                        <CustomSelect :label="$t('matter.contact')" :searchable="true"
                            v-model="form.relationship_client[index]" :options="clientOptions" />
                    </div>
                </div>
            </div>
            <div class="block__box">
                <h3>{{ $t('matter.billingReferences') }}</h3>
                <div v-if="form.financial" class="flex flex-col j-btw w-100 g-24">
                    <div class="flex w-50 g-24">
                        <CustomCheckbox id="flat_fees_check" :label="$t('matter.flatFees')"
                            v-model="form.financial.flat_fees_items" />
                    </div>
                    <div class="flex flex-col j-btw w-100 g-24">
                        <div v-for="(fee, index) in form.financial.flat_fees_items" :key="fee.id"
                            class="flex w-50 g-24">
                            <div class="flex w-50 g-16">
                                <CustomInput id="fee_note" :label="$t('matter.feeNote')"
                                    v-model="form.financial.flat_fees_items[index].title" />
                            </div>
                            <div class="flex w-50 g-16">
                                <CustomInput id="fee_amount" :label="$t('matter.feeAmount')"
                                    v-model="form.financial.flat_fees_items[index].amount_unformatted" />
                            </div>
                            <div class="flex w-50 g-16">
                                <CustomDate id="fee_due_date" :label="$t('matter.feeDueDate')"
                                    v-model:modelValue="form.financial.flat_fees_items[index].date" />
                            </div>
                            <div class="flex w-50 g-16 a-center">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 13L12.94 1M13 13L1.06 1" stroke="black" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-50 g-24">
                        <CustomInput id="contingency_fees_value" :label="$t('matter.contingencyFees')"
                            v-model="form.financial.contingency_fees_value" />
                    </div>
                </div>
            </div>
            <div class="block__box flex flex-row g-24">
                <button @click="saveMatter" :disabled="isSaving">Save</button>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyMembers, getMatterDetails, getCompanyDetails, getContacts, getPracticeAreas, saveMatterDetails } from '@/api'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import CustomInput from '@/components/form/CustomInput.vue'
import CustomTextarea from '@/components/form/CustomTextarea.vue'
import CustomSelect from '@/components/form/CustomSelect.vue'
import CustomMultiselect from '@/components/form/CustomMultiselect.vue'
import CustomDate from '@/components/form/CustomDate.vue'
import CustomCheckbox from '@/components/form/CustomCheckbox.vue'

export default {
  components: {
    Breadcrumbs,
    CustomInput,
    CustomTextarea,
    CustomSelect,
    CustomMultiselect,
    CustomDate,
    CustomCheckbox
  },
  data () {
    return {
      dateFrom: '',
      statusOptions: [],
      practiceOptions: [],
      solicitorOptions: [],
      clientOptions: [],
      form: {
        name: '',
        contact_details: { id: null },
        description: '',
        responsible_solicitor: { id: null },
        originating_solicitor: { id: null },
        matter_number: '',
        location: '',
        practice_area: { id: null },
        open_date: '',
        close_date: '',
        pending_date: '',
        limit_date: null,
        limit_date_satisfied: false
      },
      loading: false,
      company: null,
      companyId: null,
      matterId: null,
      isSaving: false,
      message: null
    }
  },
  created () {
    this.companyId = this.$route.params.companyId
    this.matterId = this.$route.params.matterId
    this.loadInitialData()
  },
  methods: {
    async saveMatter () {
      this.isSaving = true
      this.message = null

      console.log('active: ', this.form.status_active)
      console.log('description: ', this.form.description)
      console.log('name: ', this.form.name)
      console.log('client: ', this.form.contact_details.id)
      console.log('responsible_solicitor: ', this.form.responsible_solicitor)
      console.log('relationship_client: ', this.form.relationship_client)
      console.log('date: ', this.form.open_date)
      console.log('fees: ', this.form.financial.flat_fees_items)
      console.log('status: ', this.form.status?.id)
      console.log('matter_number', this.form.client_refer_number)
      const formData = new FormData()

      // Append all fields from your form to the formData object
      formData.append('status_active', this.form.status_active)
      formData.append('name', this.form.name)
      formData.append('client', this.form.contact_details?.id)
      formData.append('description', this.form.description)
      formData.append('responsible_solicitor', this.form.responsible_solicitor?.id)
      formData.append('originating_solicitor', this.form.originating_solicitor?.id)
      formData.append('matter_number', this.form.client_refer_number)
      formData.append('location', this.form.location)
      formData.append('permissions[]', this.form.permissions?.join(','))
      formData.append('practice_area', this.form.practice_area.id)
      formData.append('status', this.form.status?.id)
      formData.append('open_date', this.form.open_date)
      formData.append('close_date', this.form.close_date)
      formData.append('pending_date', this.form.pending_date)
      formData.append('limit_date', this.form.limit_date)
      formData.append('limit_date_satisfied', this.form.limit_date_satisfied)
      // Assuming relationship_client is an array of client ids
      this.form.relationship_client?.forEach(client => {
        formData.append('relationship_client[]', client.id)
        formData.append('relationship_description[]', client.relationship_description)
      })

      formData.append('flat_fees_check', this.form.flat_fees_check)
      formData.append('contingency_fees_value', this.form.financial.contingency_fees_value)

      // Additional handling for flat fees
      this.form.flat_fees_items?.forEach(item => {
        formData.append('flat_fees_ids[]', item.id)
        formData.append('flat_fees_description[]', item.description)
        formData.append('flat_fees_value[]', item.value)
        formData.append('flat_fees_due_date[]', item.due_date)
      })

      // Example of notifying users
      this.form.notify_users?.forEach(userId => {
        formData.append('notify_users[]', userId)
      })

      try {
        const response = await saveMatterDetails(this.companyId, this.matterId, formData)
        if (response.status === 'Success') {
          this.message = 'Matter saved successfully!'
        } else {
          this.message = 'Error: Failed to save matter details.'
        }
      } catch (error) {
        console.error('Failed to save matter details:', error)
        this.message = 'Error: Failed to save matter details.'
      } finally {
        this.isSaving = false
      }
    },
    async loadInitialData () {
      try {
        this.loading = true
        const companyResponse = await getCompanyDetails(this.companyId)
        this.company = companyResponse.data
        this.updateBreadcrumbs()

        const [solicitorsResponse, clientResponse, practiceResponse] = await Promise.all([
          getCompanyMembers(this.companyId),
          getContacts(this.companyId, { pageSize: 'all' }),
          getPracticeAreas(this.companyId)
        ])

        this.solicitorOptions = solicitorsResponse.data.map(user => ({
          value: user.id,
          name: user.name || user.email
        }))
        this.clientOptions = clientResponse.data.contacts.map(client => ({
          value: client.id,
          name: client.is_company ? client.first_name : (client.first_name + ' ' + client.last_name).trim()
        }))
        this.practiceOptions = practiceResponse.data.map(practice => ({
          value: practice.id,
          name: practice.name
        }))

        const matterResponse = await getMatterDetails(this.companyId, this.matterId)
        this.form = { ...this.form, ...matterResponse.data }

        this.statusOptions = [
          { value: 'open', name: 'Open' },
          { value: 'close', name: 'Close' }
        ]
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Companies', path: '/companies' },
        { name: this.company.name, path: `/companies/${this.companyId}` },
        { name: 'Matters', path: `/companies/${this.companyId}/matters` },
        { name: `Matter #${this.matterId}`, path: '' } // Последний элемент без ссылки
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  }
}
</script>

<style scoped>
.block__box {
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.block__box h3 {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
    margin-bottom: 16px;
}
</style>
